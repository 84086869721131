import type { Member } from '@engage/members/models/Member';
import { isSelf } from '@engage/members/models/Member';
import { toWorkoutHistoryPath } from '@engage/overview/urls/workouts';
import type { WorkoutCounts } from './models/WorkoutCounts';
import { ViewTypes } from './workoutViewTypes';

export const toWorkoutCountsView = (counts: WorkoutCounts, member: Member) =>
  counts.totalWorkouts === 0
    ? toZeroStateView(counts, member)
    : toWithWorkoutsView(counts, member);

const toZeroStateView = (counts: WorkoutCounts, member: Member) => ({
  kind: ViewTypes.ZeroState,
  workoutCounts: counts.workouts,
  showButton: isSelf(member),
  isSelf: isSelf(member),
});

const toWithWorkoutsView = (counts: WorkoutCounts, member: Member) => ({
  kind: ViewTypes.WithWorkouts,
  totalWorkouts: counts.totalWorkouts,
  workoutCounts: counts.workouts,
  buttonLink: toWorkoutHistoryPath(isSelf(member), member.id),
});
