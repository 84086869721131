import { getExtLinkEnv, toWWWLink } from '@peloton/external-links';
import { toHref } from '@peloton/external-links/models';
import {
  toSubDetailsUrl,
  FREE_SUB_ID,
  switchPlanAccount,
  upgrade1wFreeAccount,
} from '@peloton/links/account';
import { useReduxState } from '@engage/redux';
import { getOwnSubscriptions, getActiveSubs } from '@engage/subscriptions';

export const useUpgradeOrReactivate = (
  hasBenefit: boolean = false,
  trackingParams?: { source: string; sourceDetail?: string },
) => {
  const subs = useReduxState(getOwnSubscriptions);
  const hasActiveSub = Boolean(getActiveSubs(subs).length);
  const hasSubsNoActiveSub = subs && subs.length > 0 && !hasActiveSub;
  const subid = subs?.[0]?.id;
  const linkEnv = useReduxState(getExtLinkEnv);
  let linkWithTrackingParams = hasSubsNoActiveSub
    ? toHref(toWWWLink(toSubDetailsUrl(subid)), linkEnv)
    : toHref(
        hasBenefit ? upgrade1wFreeAccount() : switchPlanAccount(FREE_SUB_ID),
        linkEnv,
      );

  if (trackingParams) {
    const { source, sourceDetail } = trackingParams;
    const encodedSourceParam = `source=${encodeURIComponent(source)}`;
    const encodedSourceDetailParam =
      sourceDetail && `sourceDetail=${encodeURIComponent(sourceDetail)}`;

    linkWithTrackingParams = linkWithTrackingParams + '?' + encodedSourceParam;
    if (encodedSourceDetailParam) {
      linkWithTrackingParams = linkWithTrackingParams + '&' + encodedSourceDetailParam;
    }
  }

  return linkWithTrackingParams;
};
