import type { Client } from '@peloton/api';
import { pipeData, toSkipErrorHandlingConfig } from '@peloton/api';
import type { RideSubsegment } from '@engage/api-v2-models';
import type { ApiClassDetails } from '@engage/classes';
import type { MemberInfo } from '@engage/members/models/Member';
import type { Playlist, RadioData } from '@engage/video-ui';
import { config } from './config';
import { toClassDetail, toFriendsList } from './mappers';

const toClassUrl = (id: string) =>
  `/api/ride/${id}/details?stream_source=${config.streamSource}&include=sampled_top_tags`;
const toFriendsWhoHaveTakenClassUrl = (id: string) =>
  `api/ride/${id}/recent_following_workouts?joins=user&limit=200`;

export type ApiSegment = {
  id: string;
  startTimeOffset: number;
  length: number;
  name: string;
  metricsType: string;
  intensityInMets: number;
  iconName: string;
  iconSlug: string;
  iconUrl: string;
  subsegmentsV2?: Array<RideSubsegment>;
};

type ApiSegments = {
  segmentList?: ApiSegment[];
};

export type Averages = {
  averageTotalWork?: number;
  averageDistance?: number;
  averageElevation?: number;
  averageCalories?: number;
  averageAvgPower?: number;
  averageAvgIncline?: number;
  averageAvgSpeed?: number;
  averageAvgPace?: number;
  averageAvgCadence?: number;
  averageAvgResistance?: number;
  totalHeartRateZoneDurations?: {
    heartRateZ1Duration?: number;
    heartRateZ2Duration?: number;
    heartRateZ3Duration?: number;
    heartRateZ4Duration?: number;
    heartRateZ5Duration?: number;
  };
  averageEffortScore?: number;
  avgFollowAlongScoreBySubsegmentId?: Array<{
    subsegmentId?: string;
    avgScore?: number;
  }>;
  classAvgFollowAlongScore?: number;
};

export type ApiClassDetail = {
  playlist: Playlist;
  radioData?: RadioData;
  segments?: ApiSegments;
  ride: ApiClassDetails;
  averages: Averages;
  sampledTopTags: string[];
};

export type ApiFriendsResponse = {
  data: {
    user: MemberInfo;
  }[];
};

export const fetchClassDetail = (api: Client, id: string) =>
  api
    .get(toClassUrl(id), { ...toSkipErrorHandlingConfig({}, { 403: true }) })
    .then(pipeData(toClassDetail));

export const fetchFriendsWhoHaveTakenClass = (api: Client, id: string) =>
  api
    .get(toFriendsWhoHaveTakenClassUrl(id), {
      ...toSkipErrorHandlingConfig({}, { 403: true }),
    })
    .then(pipeData(toFriendsList));

export type ClassDetailResponse = ReturnType<typeof toClassDetail>;
