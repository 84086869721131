import {
  propEq,
  compose,
  not,
  cond,
  isNil,
  T as RT,
  identity,
  always,
  propOr,
} from 'ramda';
import type { Reducer } from 'redux';
import type { TypedAction } from '@peloton/redux';
import type {
  DataState,
  FetcherSelectorState,
  FetchRequestAction,
} from '@peloton/redux-fetch';
import { getEntity, getIsLoading, requestAction } from '@peloton/redux-fetch';
import { isDefined } from '@peloton/types';
import type { Achievement } from '@engage/achievement-template';
import { fetchPerformance } from './api';
import type {
  ClassWorkout,
  DetailedWorkout,
  EndedWorkout,
  WorkoutMetrics,
} from './models';
import { MetricsType } from './models';
import type { Workout } from './models/Workout';
import type { WorkoutPerformance } from './models/WorkoutPerformance';

export const workoutPerformanceNamespace = (workoutId: string) =>
  `workoutPerformance/${workoutId}`;

export const workoutsReducer: Reducer<WorkoutsReducerState> = (
  state = {},
  action: WorkoutAction,
) => {
  switch (action.type) {
    case WorkoutActionType.Request:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isLoading: true,
        } as DataState<Workout>,
      };
    case WorkoutActionType.RequestSuccess:
      return {
        ...state,
        [action.payload.id]: {
          entity: action.payload,
          isLoading: false,
          error: undefined,
        } as DataState<Workout>,
      };
    case WorkoutActionType.CompleteSuccess:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          entity: {
            ...state[action.payload.id].entity,
            ...action.payload,
          },
        } as DataState<DetailedWorkout>,
      };
    case WorkoutActionType.Update:
      return {
        ...state,
        ...indexById(state, action.payload.workouts),
      };
    default:
      return state;
  }
};

const indexById = (
  state: WorkoutsReducerState,
  workouts: Workout[],
): WorkoutsReducerState =>
  workouts.reduce(
    (acc, workout) => ({
      ...acc,
      [workout.id]: {
        entity: {
          ...state[workout.id]?.entity,
          ...workout,
        },
        isLoading: false,
        error: undefined,
      },
    }),
    {},
  );

type WorkoutsReducerState = Record<string, DataState<Workout | DetailedWorkout>>;

export type WorkoutSelectorState = {
  workouts: WorkoutsReducerState;
};

export const getWorkoutSummaries = (state: FetcherSelectorState, workoutId: string) => {
  const performance = getEntity<WorkoutPerformance>(
    state,
    workoutPerformanceNamespace(workoutId),
  );
  return isDefined(performance)
    ? {
        total: performance.total,
        average: performance.average,
      }
    : {
        total: [],
        average: [],
      };
};

export const getWorkoutLocationData = (
  state: FetcherSelectorState,
  workoutId: string,
) => {
  const workout = getEntity<WorkoutPerformance>(
    state,
    workoutPerformanceNamespace(workoutId),
  );
  return workout?.locationData || [];
};

export const getWorkoutEffortZones = (state: FetcherSelectorState, workoutId: string) => {
  const workout = getEntity<WorkoutPerformance>(
    state,
    workoutPerformanceNamespace(workoutId),
  );
  return workout?.effortZones ?? null;
};

export const getIsLoadingWorkoutSummaries = (
  state: FetcherSelectorState,
  workoutId: string,
) => getIsLoading(state, workoutPerformanceNamespace(workoutId));

export const getWorkoutMetrics = (
  state: FetcherSelectorState,
  workoutId: string,
): WorkoutMetrics | undefined => {
  const workout = getEntity<WorkoutPerformance>(
    state,
    workoutPerformanceNamespace(workoutId),
  );
  return isDefined(workout)
    ? {
        metrics: workout.metrics,
      }
    : undefined;
};

export const getSplits = (state: FetcherSelectorState, workoutId: string) => {
  const workout = getEntity<WorkoutPerformance>(
    state,
    workoutPerformanceNamespace(workoutId),
  );
  return workout?.splits;
};

export const getSegments = (state: FetcherSelectorState, workoutId: string) => {
  const workout = getEntity<WorkoutPerformance>(
    state,
    workoutPerformanceNamespace(workoutId),
  );
  return workout?.segments;
};

export const getIsLocationDataInaccurate = (
  state: FetcherSelectorState,
  workoutId: string,
) => {
  const workout = getEntity<WorkoutPerformance>(
    state,
    workoutPerformanceNamespace(workoutId),
  );
  return workout?.isLocationDataInaccurate ?? undefined;
};

export const getWorkout = (
  state: WorkoutSelectorState,
  id: string,
): Workout | DetailedWorkout<ClassWorkout> | undefined =>
  state.workouts[id]?.entity ?? state.workouts[id.replace(/-/g, '')]?.entity;

export const getAchievements = (state: WorkoutSelectorState, workoutId: string) => {
  // TODO: revisit when upgrading ramda (could be one compose)
  const workout = getWorkout(state, workoutId);
  const getAchievementTemplates: (
    workout: Workout | DetailedWorkout<ClassWorkout> | undefined,
  ) => Achievement[] = compose<Workout, Achievement[]>(
    propOr([], 'achievementTemplates'),
    cond([
      [isNil, always({})],
      [RT, identity],
    ]),
  );
  return getAchievementTemplates(workout);
};

export const getHasMetrics: (
  state: WorkoutSelectorState,
  workoutId: string,
) => boolean = compose(
  not,
  propEq('metricsType', MetricsType.Uncaptured),
  cond([
    [isNil, always({ metricsType: MetricsType.Uncaptured })],
    [RT, identity],
  ]),
  getWorkout,
);

type LeaderboardProviding = { leaderboardRank: number; totalLeaderboardUsers: number };
const hasLeaderboardInfo = (workout: any): workout is LeaderboardProviding =>
  workout && !isNaN(workout.leaderboardRank) && !isNaN(workout.totalLeaderboardUsers);
const toLeaderboardInfo = (workout: any) =>
  hasLeaderboardInfo(workout)
    ? {
        totalLeaderboardUsers: workout.totalLeaderboardUsers,
        leaderboardRank: workout.leaderboardRank,
      }
    : undefined;
export const getLeaderboardInfo = (state: WorkoutSelectorState, workoutId: string) => {
  const entity = getWorkout(state, workoutId);
  return toLeaderboardInfo(entity);
};

export const getWorkoutUserId = (state: WorkoutSelectorState, workoutId: string) => {
  const entity = getWorkout(state, workoutId);
  return entity && entity.userId;
};

export type WorkoutRequestSuccessAction = TypedAction<
  ReturnType<typeof loadWorkoutSuccess>,
  WorkoutActionType.RequestSuccess
>;
export type WorkoutRequestAction = TypedAction<
  ReturnType<typeof loadWorkout>,
  WorkoutActionType.Request
>;
export type DeleteWorkoutAction = TypedAction<
  ReturnType<typeof removeWorkout>,
  WorkoutActionType.DeleteRequest
>;
export type WorkoutUpdateAction = TypedAction<
  ReturnType<typeof updateWorkouts>,
  WorkoutActionType.Update
>;
export type CompleteWorkoutAction = TypedAction<
  ReturnType<typeof completeWorkout>,
  WorkoutActionType.CompleteRequest
>;
export type CompleteWorkoutSuccessAction = TypedAction<
  ReturnType<typeof completeWorkoutSuccess>,
  WorkoutActionType.CompleteSuccess
>;

type WorkoutAction =
  | WorkoutRequestAction
  | WorkoutRequestSuccessAction
  | DeleteWorkoutAction
  | WorkoutUpdateAction
  | CompleteWorkoutAction
  | CompleteWorkoutSuccessAction;

export enum WorkoutActionType {
  Request = 'engage/workout/Request',
  RequestSuccess = 'engage/workout/RequestSuccess',
  DeleteRequest = 'engage/workout/DeleteRequest',
  DeleteSuccess = 'engage/workout/DeleteSuccess',
  CreateSuccess = 'engage/workout/CreateSuccess',
  Update = 'engage/workout/Update',
  CompleteRequest = 'engage/workout/CompleteRequest',
  CompleteSuccess = 'engage/workout/CompleteSuccess',
  CompleteFailure = 'engage/workout/CompleteFailure',
}

export const loadWorkout = (id: string) => ({
  type: WorkoutActionType.Request,
  payload: { id },
});

export const loadWorkoutSuccess = (workout: Workout) => ({
  type: WorkoutActionType.RequestSuccess,
  payload: workout,
});

export const completeWorkout = (id: string) => ({
  type: WorkoutActionType.CompleteRequest,
  payload: { id },
});

export const completeWorkoutSuccess = (workout: EndedWorkout) => ({
  type: WorkoutActionType.CompleteSuccess,
  payload: workout,
});

export const completeWorkoutFailure = () => ({
  type: WorkoutActionType.CompleteFailure,
});

export const removeWorkout = (id: string) => ({
  type: WorkoutActionType.DeleteRequest,
  payload: { id },
});

export const removeWorkoutSuccess = (id: string) => ({
  type: WorkoutActionType.DeleteSuccess,
  payload: { id },
});

export const updateWorkouts = (workouts: Workout[]) => ({
  type: WorkoutActionType.Update,
  payload: { workouts },
});

// Workout Summary uses Fetcher
export const loadWorkoutSummary = (
  workoutId: string,
): FetchRequestAction<WorkoutPerformance> =>
  requestAction(workoutPerformanceNamespace(workoutId), fetchPerformance, workoutId);
