import { allPass, isNil, map, propIs } from 'ramda';
import React from 'react';
import type { CopyHook } from '@peloton/copy-generic';
import type { InputProps } from './ecomm-ports';
import StyledContainer from './ecomm-ports/StyledContainer';

export type LocalizedOption<LabelId extends string> = {
  label: LabelId;
  value: string | number;
};

const notNil = (v: unknown) => !isNil((v as any)?.value);
const isLocalizedOption = allPass([propIs(String, 'label'), notNil]) as <
  T extends string
>(
  maybe: unknown,
) => maybe is LocalizedOption<T>;

type Props<Option extends string> = Omit<InputProps<HTMLSelectElement>, 'options'> & {
  options: Option[] | LocalizedOption<Option>[];
  renderer: CopyHook<Option>;
  isDefault?: boolean;
};

const LocalizedSelect = <T extends string>({
  label,
  required,
  onFocus,
  onChange,
  getRef,
  options,
  value,
  renderer,
  className,
  isDefault,
  ...props
}: Props<T>) => {
  const [userFocused, setFocus] = React.useState(false);
  const [controlledValue, setValue] = React.useState('');

  const handleChange = React.useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    setValue(e.currentTarget.value);
  }, []);

  const handleFocus = React.useCallback(
    (e: React.FocusEvent<HTMLSelectElement>) => {
      if (onFocus) {
        onFocus(e);
      }

      setFocus(true);
    },
    [onFocus],
  );

  const selected = value || controlledValue;
  // https://github.com/microsoft/TypeScript/issues/36390

  const renderedOptions = map(
    option => (
      <SelectOption
        key={typeof option === 'string' ? option : option.value}
        option={option}
        selected={selected}
        renderer={renderer}
      />
    ),
    options,
  );

  return React.useMemo(() => {
    return (
      <StyledContainer className={className}>
        <select
          {...props}
          aria-label={label}
          aria-required={required}
          data-user-focused={userFocused}
          data-value={value ?? controlledValue}
          onChange={onChange ?? handleChange}
          onFocus={handleFocus}
          ref={getRef}
          required={required}
          value={selected}
        >
          <option value="" aria-selected={selected === ''}>
            {label}
            {required ? '*' : null}
          </option>
          <>{renderedOptions}</>
        </select>
      </StyledContainer>
    );
  }, [isDefault, label, required, options, value, selected, controlledValue, onChange]);
};

type SelectOptionProps<Option extends string> = {
  selected: string | number | readonly string[];
  renderer: CopyHook<Option>;
  option: Option | LocalizedOption<Option>;
};

const SelectOption = <T extends string>({
  option,
  selected,
  renderer,
}: SelectOptionProps<T>) =>
  isLocalizedOption(option) ? (
    <option
      key={option.value}
      value={option.value}
      aria-selected={selected === option.value}
    >
      {renderer(option.label)}
    </option>
  ) : (
    <option key={`${option}`} value={option} aria-selected={selected === option}>
      {renderer(option)}
    </option>
  );

export default LocalizedSelect;
