import { transparentize } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';
import { hoverTransition } from '@peloton/styles';
import { white, black } from '@engage/colors';
import { sharedButtonStyles, smallButtonStyles } from '@members/buttons';
import { facebookBlue, altBlue } from './colors';
import FacebookIconSolid from './icons/FacebookIconSolid';

const facebookDarkStyles = css`
  ${sharedButtonStyles}
  ${smallButtonStyles}
  box-shadow: inset 0 -1px 0 0 ${transparentize(0.8, black)};
  color: ${white};
  fill: ${white};
  ${hoverTransition({
    property: 'background-color',
    from: facebookBlue,
    to: altBlue,
  })}
  &:active {
    background-color: ${altBlue};
  }
`;

const IconButton: React.FC<
  React.PropsWithChildren<React.HtmlHTMLAttributes<HTMLButtonElement>>
> = props => (
  <button {...props}>
    <ButtonContent>
      <Icon />
      {props.children}
    </ButtonContent>
  </button>
);

const FacebookButton = styled.button`
  ${facebookDarkStyles}
`;

const FacebookButtonLight = styled(IconButton)`
  ${sharedButtonStyles}
  color: ${facebookBlue};
  border: 1px solid ${facebookBlue};
  ${hoverTransition(
    {
      property: 'background-color',
      from: 'transparent',
      to: facebookBlue,
    },
    {
      property: 'fill',
      from: facebookBlue,
      to: white,
    },
    {
      property: 'color',
      from: facebookBlue,
      to: white,
    },
  )}
`;

const FacebookButtonDark = styled(IconButton)`
  ${facebookDarkStyles}
`;

const Icon = styled(FacebookIconSolid)`
  width: 16px;
  height: 16px;
  margin-right: 10px;
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { FacebookButton, FacebookButtonLight, FacebookButtonDark };
