import { curry, prop } from 'ramda';
import type { ClientDetails, ScreenProps } from '@peloton/analytics';
import { toClientDetailsHeader } from '@peloton/analytics';
import type { Client } from '@peloton/api';
import { pipeData, toSkipErrorHandlingConfig } from '@peloton/api';
import type { ApiMember } from '@engage/members';
import { toMembersList } from '@engage/members';
import type { FacebookFriendsResponse } from './models';
import type { FacebookCredentials } from './models/FacebookCredentials';
import type { FacebookFriendInfo } from './models/FacebookFriends';

const noop = () => undefined;

// Facebook Connect

const FACEBOOK_PERMISSIONS = {
  scope: 'user_friends,email',
  auth_type: 'rerequest',
};

export const APP_ID = 492491817438833;

export const login = (fb: FBSDK = getFB()) =>
  new Promise<FacebookCredentials>(resolve =>
    fb.login(mapFacebookResponse(resolve), FACEBOOK_PERMISSIONS),
  );

export const logout = (fb: FBSDK = getFB()) => fb.logout(noop);

export const getLoginStatus = (fb: FBSDK = getFB()) =>
  // force network request
  new Promise<FacebookCredentials>(resolve =>
    fb.getLoginStatus(mapFacebookResponse(resolve), true),
  );

export const addFacebookCredentials = (
  client: Client,
  userId: string,
  credentials: FacebookCredentials,
  analytics: ClientDetails<ScreenProps>,
) =>
  client.put(`/api/user/${userId}/add_facebook_credentials`, credentials, {
    ...toClientDetailsHeader(analytics),
    ...toSkipErrorHandlingConfig(),
  });

export const removeFacebookCredentials = (client: Client, userId: string) =>
  client.put(
    `api/user/${userId}/remove_facebook_credentials`,
    {},
    toSkipErrorHandlingConfig(),
  );

export const fetchFacebookId = (client: Client) =>
  client.get('/api/me').then(pipeData(prop('facebookId')));

export const toFacebookCredentials = (
  fbResponse: FB.LoginStatusResponse,
): FacebookCredentials | undefined =>
  fbResponse.authResponse
    ? {
        facebookId: fbResponse.authResponse.userID,
        facebookAccessToken: fbResponse.authResponse.accessToken,
        facebookTokenExpiry: fbResponse.authResponse.expiresIn,
      }
    : undefined;

const mapFacebookResponse = curry((cb: any, fbResponseObject: FB.LoginStatusResponse) =>
  cb(toFacebookCredentials(fbResponseObject)),
);

const getFB = (): FBSDK => (window as WindowWithFacebook).FB;

type WindowWithFacebook = Window & { FB: FBSDK };

// Facebook Friends

export const fetchFacebookFriends = curry(
  (userId: string, client: Client): Promise<FacebookFriendsResponse> =>
    client
      .get(`/api/user/${userId}/facebook_friends`, toSkipErrorHandlingConfig())
      .then(pipeData(toFacebookFriends)),
);

export const toFacebookFriends = ({
  total,
  data,
}: ApiFacebookResponse): FacebookFriendsResponse => ({
  total,
  membersFbInfo: toFacebookFriendInfo(data),
  members: toMembersList({ data }),
});

export const toFacebookFriendInfo = (
  friends: ApiFacebookFriend[],
): FacebookFriendInfo[] =>
  friends.map((friend: ApiFacebookFriend) => ({
    username: friend.username,
    facebookName: friend.facebookName,
  }));

export const share = (
  options: { href: string; quote?: string },
  cb: (res: any) => any = () => undefined,
  fb: FBSDK = getFB(),
) =>
  fb.ui(
    {
      method: 'share',
      ...options,
    },
    (response: any) => {
      if (response && !response.error_message) {
        cb(response);
      }
    },
  );

type ApiFacebookFriend = ApiMember & FacebookFriendInfo;

type ApiFacebookResponse = {
  total: number;
  data: ApiFacebookFriend[];
};
