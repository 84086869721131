import type { Class } from '../models/Class';

export type OptionalPayload<T extends object> = { [K in keyof T]?: T[K] };
export type UpdateClassesActionWithOptionalPayload<T extends object> = {
  type: ClassesActionTypes.Update;
  payload: Class[];
} & OptionalPayload<T>;

export enum ClassesActionTypes {
  Update = 'pelo/classes/Update',
  Clear = 'pelo/classes/Clear',
}

export const updateClasses = <T extends object>(
  payload: Class[],
  optionalPayload: OptionalPayload<T> = {},
): UpdateClassesActionWithOptionalPayload<T> => ({
  type: ClassesActionTypes.Update,
  payload,
  ...optionalPayload,
});

export const isUpdateClassesAction = <T extends object>(action: {
  type: string;
}): action is UpdateClassesActionWithOptionalPayload<T> =>
  action.type === ClassesActionTypes.Update;
