import React from 'react';
import { Link } from 'react-router-dom';
import { compose, withProps } from 'recompose';
import styled from 'styled-components';
import { withExtHrefs } from '@peloton/external-links';
import { Links } from '@peloton/links';
import { BreakpointEither } from '@peloton/responsive';
import { black, slate1 } from '@engage/colors';
import { spaces } from '@engage/styles';
import { Auth, AuthCopy, useSite, Site } from '@members/copy';
import { LOGIN_ROUTE } from '@members/pg-auth/urls';
import { navSubLink, MainText, MainLink, Separator } from '../../sharedLinkStyles';
import { topNavContainerStyles } from '../containers';
import { PelotonLogo } from '../icons/PelotonLogo';
import { User as UserIcon } from '../icons/User';

export const GuestNavView: React.FC<React.PropsWithChildren<Props>> = props => (
  <BreakpointEither breakpoint="tablet">
    <MobileGuestNavView {...props} />
    <StandardGuestNavView {...props} />
  </BreakpointEither>
);

const StandardGuestNavView: React.FC<React.PropsWithChildren<Props>> = props => (
  <Container>
    <HomeLink />
    <MainLinks>
      <MainLink to={props.pelotonHref} isExternal>
        <MainText>Peloton</MainText>
      </MainLink>
      <Separator />
      <MainLink to={props.loginHref} isExternal data-test-id="loginLink" target="_self">
        <Icon />
        <MainText>
          <AuthCopy id={Auth.GuestNavCta} />
        </MainText>
      </MainLink>
    </MainLinks>
  </Container>
);

const MobileGuestNavView: React.FC<React.PropsWithChildren<Props>> = props => (
  <MobileContainer href={props.loginHref} data-test-id="loginLink">
    <MobileNavPelotonLogo />
    <MobileUserContainer>
      <MobileIcon />
      <MobileText>
        <AuthCopy id={Auth.GuestNavCta} />
      </MobileText>
    </MobileUserContainer>
  </MobileContainer>
);

type Props = {
  pelotonHref: string;
  loginHref: string;
};

const TopNavGuest = compose(
  withExtHrefs({ pelotonHref: Links.peloton }),
  withProps((props: Props) => ({
    ...props,
    loginHref: LOGIN_ROUTE,
  })),
)(GuestNavView);

const HomeLink = () => (
  <Link aria-label={useSite(Site.HomeAriaLabel)} to="/">
    <NavPelotonLogo />
  </Link>
);

const Container = styled.div`
  ${topNavContainerStyles}
`;

const MainLinks = styled.div`
  display: flex;
  align-items: center;
`;

// Mobile size styles

const Icon = styled(UserIcon)`
  width: 16px;
  height: 16px;
  fill: ${slate1};
  margin-right: ${spaces.xSmall}px;
`;

const NavPelotonLogo = styled(PelotonLogo)`
  fill: ${black};
  height: 28px;
  width: 20px;
`;

const MobileContainer = styled.a`
  ${topNavContainerStyles}
`;

const MobileUserContainer = styled.div`
  display: flex;
  align-items: center;
`;

const MobileText = styled.span`
  ${navSubLink}
  color: ${slate1};
`;

const MobileIcon = styled(UserIcon)`
  width: 14px;
  height: 14px;
  fill: ${slate1};
  margin-right: ${spaces.xxSmall}px;
`;

const MobileNavPelotonLogo = styled(PelotonLogo)`
  fill: ${black};
  height: 23px;
  width: 16px;
`;

export default TopNavGuest;
