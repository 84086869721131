import {
  pathOr,
  compose,
  path,
  cond,
  add,
  always,
  T as otherwise,
  curry,
  is,
  map,
  filter,
  prop,
} from 'ramda';
import type { UserSelectorState } from '@peloton/auth';
import type { FetcherSelectorState } from '@peloton/redux-fetch';
import { isDefined } from '@peloton/types';
import type { ClassSelectorState } from '@engage/classes';
import {
  getDenormalizedClass,
  toInstructorNameAndImage,
  isScenic,
} from '@engage/classes';
import { getDeviceType, getFitnessDisciplineName } from '@engage/metadata';
import type { PendingResult } from '@engage/result';
import { isOk, isError, mapResult, pending } from '@engage/result';
import { getWorkout, isClassWorkout } from '@engage/workouts';
import type { WorkoutSelectorState, Workout } from '@engage/workouts';
import type { WorkoutWithClass, DisplayableWorkout, HydratedWorkout } from './models';
import { hasClass } from './models';
import type { WorkoutHistoryItem } from './models/WorkoutHistoryItem';
import type { WorkoutHistoryState } from './redux';

export type WorkoutHistorySelectorState = ClassSelectorState &
  FetcherSelectorState &
  UserSelectorState &
  WorkoutSelectorState & {
    workoutHistory: WorkoutHistoryState;
  };

const getDecoratedClass = (state: WorkoutHistorySelectorState, classId: string) => {
  const klass = getDenormalizedClass(state, classId);
  return klass
    ? {
        ...klass,
        ...toInstructorNameAndImage(klass),
      }
    : undefined;
};

const decorateWorkoutWithClass = <T extends Workout>(
  state: WorkoutHistorySelectorState,
) => (workout: T): T & WorkoutWithClass =>
  isClassWorkout(workout)
    ? { ...(workout as any), class: getDecoratedClass(state, workout.classId) }
    : workout;

const decorateWorkoutWithDeviceType = <T extends Workout>(
  state: WorkoutHistorySelectorState,
) => (workout: T): T & DisplayableWorkout => ({
  ...(workout as any),
  displayable: {
    deviceType: getDeviceType(state, workout.deviceType),
  },
});

const withFitnessDisciplineName = (state: WorkoutHistorySelectorState) => (
  workout: Workout,
) => ({
  ...workout,
  fitnessDiscipline: getFitnessDisciplineName(state, workout.fitnessDiscipline),
});

const toOutput = (output: number) => Math.round(output / 1000);

const toWorkoutHistoryItem = (workout: HydratedWorkout): WorkoutHistoryItem => ({
  classTitle: hasClass(workout) ? workout.class.title : workout.gymTitle ?? workout.title,
  deviceType: workout.displayable.deviceType,
  fitnessDiscipline: workout.fitnessDiscipline,
  hasMetrics: isDefined(workout.metricsType),
  id: workout.id,
  imageUrl: hasClass(workout)
    ? pathOr('', ['class', 'imageUrl'], workout)
    : workout.imageUrl,
  instructorName: hasClass(workout)
    ? pathOr('', ['class', 'instructorOrScenic'], workout)
    : workout.instructorName,
  isScenic: hasClass(workout) && isScenic(workout.class),
  output: toOutput(workout.totalWork),
  scheduledStartTime: path(['class', 'scheduledStartTime'], workout),
  deviceTimeCreatedAt: workout.deviceTimeCreatedAt,
  userId: workout.userId,
  effortZones: workout.effortZones,
});

// eslint-disable-next-line @typescript-eslint/no-shadow
type DoubleReturn<T extends (...args: any[]) => (...args: any[]) => any> = ReturnType<
  ReturnType<T>
>;
export const getWorkoutHistory = (
  state: WorkoutHistorySelectorState,
): PendingResult<WorkoutHistoryItem[], undefined> =>
  mapResult(
    compose(
      map(
        compose<
          Workout,
          DoubleReturn<typeof withFitnessDisciplineName>,
          DoubleReturn<typeof decorateWorkoutWithClass>,
          DoubleReturn<typeof decorateWorkoutWithDeviceType>,
          ReturnType<typeof toWorkoutHistoryItem>
        >(
          toWorkoutHistoryItem,
          decorateWorkoutWithDeviceType(state),
          decorateWorkoutWithClass(state),
          withFitnessDisciplineName(state),
        ),
      ),
      filter(Boolean) as any,
      map(curry(getWorkout)(state)),
      prop('ids'),
    ),
    state.workoutHistory,
  );

export const getWorkoutIds = (
  state: WorkoutHistorySelectorState,
): PendingResult<string[], undefined> => mapResult(prop('ids'), state.workoutHistory);

export const getWorkoutHistoryItem = (
  state: WorkoutHistorySelectorState & WorkoutSelectorState,
  id: string,
) => {
  const provisionalWorkout = getWorkout(state, id);

  if (!provisionalWorkout) {
    return undefined;
  }

  return compose<
    Workout,
    DoubleReturn<typeof withFitnessDisciplineName>,
    DoubleReturn<typeof decorateWorkoutWithClass>,
    DoubleReturn<typeof decorateWorkoutWithDeviceType>,
    ReturnType<typeof toWorkoutHistoryItem>
  >(
    toWorkoutHistoryItem,
    decorateWorkoutWithDeviceType(state),
    decorateWorkoutWithClass(state),
    withFitnessDisciplineName(state),
  )(provisionalWorkout);
};

export const getTotalWorkouts = (state: WorkoutHistorySelectorState) =>
  pathOr(0, ['workoutHistory', 'value', 'entityCount'], state);

type NextPageCount = (state: WorkoutHistorySelectorState) => number | undefined;

export const getNextPageCount: NextPageCount = compose(
  cond([
    [is(Number), add(1)],
    [otherwise, always(undefined)],
  ]),
  path(['workoutHistory', 'value', 'nextPage', 'value']),
);

type isWorkoutPrivate = (state: WorkoutHistorySelectorState) => boolean | undefined;

export const getIsWorkoutPrivate: isWorkoutPrivate = compose(
  path(['workoutHistory', 'value', 'isPrivate']),
);

export const getNextPageToLoad = (
  state: WorkoutHistorySelectorState,
): number | undefined => {
  if (isOk(state.workoutHistory)) {
    const nextPage = state.workoutHistory.value.nextPage;
    if (isOk(nextPage)) {
      return typeof nextPage.value === 'number' ? nextPage.value : undefined;
    } else {
      return isError(nextPage) ? nextPage.value.page : undefined;
    }
  } else {
    return 0;
  }
};

export const getAreWorkoutsLoaded = (state: WorkoutHistorySelectorState) =>
  isOk(state.workoutHistory);

export const getIsNextPageLoading = (state: WorkoutHistorySelectorState) =>
  isOk(state.workoutHistory) && state.workoutHistory.value.nextPage === pending;
