import { identity } from 'ramda';
import type { Client } from '@peloton/api';
import { pipeData, toSkipErrorHandlingConfig } from '@peloton/api';
import type { Member, ApiMembersResponse } from '@engage/members';
import { toMembersList } from '@engage/members/api';
import type { ApiNextPage, PageNumber } from '@engage/pagination';
import { toNextPage } from '@engage/pagination';
import { toActiveDayHistory } from './ActivityCalendar/toActiveDayHistory';
import type { ApiActivityCalendar } from './models/ApiActivityCalendar';
import { FriendType } from './models/FriendType';
import type { Overview } from './models/Overview';

const REQUEST_LIMIT = 100;

export const fetchFriends = (id: string, friendType: FriendType, page: number = 0) => (
  api: Client,
): Promise<FriendsData> =>
  api.get(toFriendsUrl[friendType](id, page)).then(pipeData(toFriendsData));

const toFollowingUrl = (id: string, page: number) =>
  `api/user/${id}/following?limit=${REQUEST_LIMIT}&page=${page}`;

const toFollowersUrl = (id: string, page: number) =>
  `api/user/${id}/followers?limit=${REQUEST_LIMIT}&page=${page}`;

const toFriendsUrl = {
  [FriendType.Follower]: toFollowersUrl,
  [FriendType.Following]: toFollowingUrl,
};

const toFriendsData = (response: ApiFriendsResponse): FriendsData => ({
  friends: toMembersList(response),
  nextPage: toNextPage(response),
});

const toOverviewUrl = (id: string) => `api/user/${id}/overview?version=1`;

export const fetchOverview = (api: Client, id: string): Promise<Overview> =>
  api.get(toOverviewUrl(id), toSkipErrorHandlingConfig()).then(pipeData(identity));

const toCalendarUrl = (userId: string) => `/api/user/${userId}/calendar`;

export const fetchActiveDayHistory = (api: Client, userId: string) =>
  api
    .get<ApiActivityCalendar>(toCalendarUrl(userId), toSkipErrorHandlingConfig())
    .then(pipeData(toActiveDayHistory));

type ApiFriendsResponse = ApiMembersResponse & ApiNextPage;

type FriendsData = {
  friends: Member[];
  nextPage: PageNumber;
};
