import type { SagaIterator } from 'redux-saga';
import { buffers } from 'redux-saga';
import {
  call,
  getContext,
  take,
  actionChannel,
  select,
  fork,
  cancel,
  cancelled,
  put,
  takeLatest,
} from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT } from '@peloton/api';
import type { SignedInUser } from '@peloton/auth';
import {
  CaloriesMetricCollector,
  SubsegmentIdMetricCollector,
  HeartRateMetricCollector,
  AvgHeartRateMetricCollector,
  MaxHeartRateMetricCollector,
} from '../MetricRepository';
import { MetricRepository } from '../MetricRepository/MetricRepository';

import type { Packet, PacketMetrics } from '../models';
import {
  calculateWithMETs,
  getSegmentIntensityInMET,
  calculateWithHeartRateCalorieAlgorithm,
} from '../models';
import { getPacketTimingInfo, getPacketWorkoutAndUserInfo } from '../packetSelectors';
import type { TimecodeAction } from '../redux';
import {
  TimecodeActionType,
  TimelineActionType,
  VideoStreamActionType,
  reportCumulativeCalories,
  getMetrics,
} from '../redux';
import { getCurrentSegment } from '../segmentSelectors';
import getLastCompletedSubsegment from './getLastCompletedSubsegment';
import { batchSendPackets } from './packetBatching';

const packetCollectorSaga = function* (
  client: Client,
  queuedPacketSendThreshold: number,
): SagaIterator {
  // Wait for stream info to populate
  yield take(VideoStreamActionType.videoStreamSuccess);

  const { user, workoutId, supportedMetrics }: PacketInfo = yield select(
    getPacketWorkoutAndUserInfo,
  );

  // Create Metric Repository
  const metricRepo = new MetricRepository(
    {
      calories: CaloriesMetricCollector,
      heartRate: HeartRateMetricCollector,
      avgHeartRate: AvgHeartRateMetricCollector,
      maxHeartRate: MaxHeartRateMetricCollector,
      subsegmentId: SubsegmentIdMetricCollector,
    },
    supportedMetrics,
  );

  yield call(persistMetricsSaga, metricRepo);

  // Wait for segments to load
  yield take(TimelineActionType.Success);

  // Create channel to queue timecode updates
  const timecodeChannel = yield actionChannel(
    TimecodeActionType.Update,
    buffers.sliding(10),
  );
  let packetQueue: Packet[] = [];

  try {
    while (true) {
      const timecodeAction: TimecodeAction = yield take(timecodeChannel);
      if (timecodeAction.payload.isScrubbing) {
        continue;
      }

      const currentSegment = yield select(getCurrentSegment);
      const currentMET = getSegmentIntensityInMET(currentSegment);
      const { heartRate, avgHeartRate, maxHeartRate } = yield select(getMetrics);
      const { secondsOffsetFromStart, shouldAddMetrics }: TimingInfo = yield select(
        getPacketTimingInfo,
      );

      if (shouldAddMetrics) {
        metricRepo.addMetrics(secondsOffsetFromStart, {
          calories:
            heartRate > 0
              ? calculateWithHeartRateCalorieAlgorithm(heartRate, user)
              : calculateWithMETs(currentMET, user),
          heartRate: heartRate ?? 0,
          avgHeartRate: avgHeartRate ?? 0,
          maxHeartRate: maxHeartRate ?? 0,
          subsegmentId: getLastCompletedSubsegment(
            currentSegment?.subsegmentsV2,
            secondsOffsetFromStart,
          )?.id,
        });
        packetQueue.push(metricRepo.getPacket(secondsOffsetFromStart));
        const values = metricRepo.getValues(secondsOffsetFromStart) as any;
        if (values.calories) {
          yield put(reportCumulativeCalories(values.calories));
        }
      }

      if (packetQueue.length >= queuedPacketSendThreshold) {
        packetQueue = yield call(batchSendPackets, client, workoutId, packetQueue);
      }
    }
  } finally {
    if (yield cancelled()) {
      if (packetQueue.length) {
        // Clear out the queue on leaving workout
        yield call(batchSendPackets, client, workoutId, packetQueue);
      }

      // If we don't close the channel, it'll have a buffer overflow on the next video run
      yield call(timecodeChannel.close);
    }
  }
};

export const persistMetricsSaga = function* (
  metricRepo: MetricRepository<any>,
): SagaIterator {
  const persistedMetrics = yield select(getMetrics);
  if (persistedMetrics) {
    metricRepo.addMetrics(0, persistedMetrics);
  }
};

export const packetCollectorManagerSaga = function* (
  client: Client,
  queuedPacketSendThreshold: number,
  stopActionTypes: string[],
  action: any,
): SagaIterator {
  const collectorTask = yield fork(
    packetCollectorSaga,
    client,
    queuedPacketSendThreshold,
  );
  yield take(stopActionTypes);
  yield cancel(collectorTask);
};

export const packetSaga = function* (
  queuedPacketSendThreshold: number,
  startActionTypes: string[],
  stopActionTypes: string[],
): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);

  yield takeLatest(
    startActionTypes,
    packetCollectorManagerSaga,
    client,
    queuedPacketSendThreshold,
    stopActionTypes,
  );
};

type MetricKeys = (keyof PacketMetrics)[];

type PacketInfo = {
  workoutId: string;
  supportedMetrics: MetricKeys;
  user: SignedInUser;
};

type TimingInfo = {
  secondsOffsetFromStart: number;
  shouldAddMetrics: boolean;
};
