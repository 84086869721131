import type { Reducer } from 'redux';
import type { FailAction } from '@peloton/redux-fetch';
import { toFail } from '@peloton/redux-fetch';
import { isDefined } from '@peloton/types';
import type {
  AvatarUploaderSuccessAction,
  ReceiveDefaultAvatarAction,
} from '@engage/avatar-updater';
import { AvatarUploaderActions } from '@engage/avatar-updater';
import type {
  ApproveFollowerSuccessAction,
  RejectFollowerSuccessAction,
  RemoveFollowerSuccessAction,
} from '@engage/me';
import { RelationshipsActionType } from '@engage/me';
import type { MemberInfo, MemberRelationship, RelationshipChange } from './models';
import { isSelf, ME, RelationshipCategory, MemberListGroup } from './models';
import type { Member } from './models/Member';

export enum MembersActionType {
  MemberRequest = 'pelo/member/REQUEST',
  MemberUpdate = 'pelo/@members/members/UPDATE',
  ToggleFollowRequest = 'pelo/follow/TOGGLE_REQUEST',
  FollowRequest = 'pelo/follow/FOLLOW_REQUEST',
  MemberSuccess = 'pelo/member/REQUEST_SUCCESS',
  MembersListSuccess = 'pelo/membersList/REQUEST_SUCCESS',
  ToggleFollowSuccess = 'pelo/follow/TOGGLE_REQUEST_SUCCESS',
  RequestFailure = 'pelo/member/REQUEST_FAILURE',
  UpdateMemberShareChallengeFinisher = 'pelo/member/CHANGE_CHALLENGE_FINISHER',
}

export const changeRelationship = (
  source: string,
  namespace: string,
): ChangeRelationshipRequestAction => ({
  type: MembersActionType.ToggleFollowRequest,
  payload: { namespace },
  meta: { source },
});

export const loadMember = (userIdOrUsername: string): MemberRequestAction => ({
  type: MembersActionType.MemberRequest,
  payload: {
    userIdOrUsername,
  },
});

export const memberSuccess = (payload: Member): MemberSuccessAction => ({
  type: MembersActionType.MemberSuccess,
  payload,
});

export const membersListSuccess = (members: Member[]) => ({
  type: MembersActionType.MembersListSuccess,
  payload: members,
});

export const updateMemberInfo = (
  payload: Partial<MemberInfo> & Pick<MemberInfo, 'id'>,
) => ({
  type: MembersActionType.MemberUpdate,
  payload,
});

export const followRequest = (userId: string): MemberFollowAction => ({
  type: MembersActionType.FollowRequest,
  payload: {
    userId,
  },
});

export const updateMemberShareChallengeFinisherSetting = (
  shareCorporateWellnessCompletion: boolean | null,
) => ({
  type: MembersActionType.UpdateMemberShareChallengeFinisher,
  payload: { shareCorporateWellnessCompletion },
});

export const changeRelationshipSuccess = (
  followedId: string,
  selfId: string,
  relationship: MemberRelationship,
  relationshipChange: RelationshipChange,
): ChangeRelationshipSuccessAction => ({
  type: MembersActionType.ToggleFollowSuccess,
  payload: {
    relationship,
    relationshipChange,
    selfId,
    followedId,
  },
});

export const memberFail = toFail(MembersActionType.RequestFailure);

export const memberReducer: Reducer<MemberState> = (
  state: MemberState = defaultState,
  action: Action,
) => {
  switch (action.type) {
    case MembersActionType.MemberSuccess:
      return { ...state, ...mergeMember(action.payload) };

    case MembersActionType.MembersListSuccess:
      if (action.payload.length === 0) {
        return state;
      }

      return { ...state, ...mergeMembers(state, action.payload) };

    case MembersActionType.ToggleFollowSuccess: {
      const updated = mergeRelationshipChange(
        state,
        action.payload.followedId,
        action.payload.relationship,
      ) as Member;
      return {
        ...state,
        ...mergeMember(updated),
      };
    }

    case MembersActionType.UpdateMemberShareChallengeFinisher: {
      const me = state[ME]!;
      return {
        ...state,
        ...mergeMember({
          ...me,
          corporateWellnessGroup: {
            ...me.corporateWellnessGroup,
            shareCorporateWellnessCompletion:
              action.payload.shareCorporateWellnessCompletion,
          },
        }),
      };
    }

    case RelationshipsActionType.ApproveSuccess: {
      const approvedMember = mergeUpdateFromFriendAction(
        state,
        action.payload.followerId,
        true,
      );
      return {
        ...state,
        ...mergeMember(approvedMember),
      };
    }

    case RelationshipsActionType.RemoveSuccess:
    case RelationshipsActionType.RejectSuccess: {
      const rejectedMember = mergeUpdateFromFriendAction(
        state,
        action.payload.followerId,
        false,
      );
      return {
        ...state,
        ...mergeMember(rejectedMember),
      };
    }

    case AvatarUploaderActions.Default: {
      const me = state[ME]!;

      return {
        ...state,
        ...mergeMember({ ...me, defaultImageUrl: action.payload?.imageUrl }),
      };
    }

    case AvatarUploaderActions.RequestSuccess: {
      const me = state[ME]!;

      return {
        ...state,
        ...mergeMember({ ...me, imageUrl: action.payload.imageUrl }),
      };
    }
    default:
      return state;
  }
};

const mergeMember = (member?: Member) =>
  isDefined(member) ? { [isSelf(member) ? ME : member.id]: member } : {};

export const mergeMembers = (state: MemberState, members: Member[]) =>
  members.reduce(
    (newState: MemberState, member: Member) => ({ ...newState, ...mergeMember(member) }),
    state,
  );

const mergeUpdateFromFriendAction = (
  state: MemberState,
  followerId: string,
  isApproved: boolean,
) => {
  const member = state[followerId];
  if (!isDefined(member)) {
    return undefined;
  }
  const relationship = {
    ...member.relationship,
    userToMe: isApproved ? RelationshipCategory.Following : RelationshipCategory.None,
  };
  const listGroup = isApproved ? MemberListGroup.Following : MemberListGroup.Other;
  return mergeRelationshipChange(state, followerId, relationship, listGroup);
};

const mergeRelationshipChange = (
  state: MemberState,
  toFollowId: string,
  relationship: MemberRelationship,
  listGroup?: MemberListGroup,
): Member | undefined => {
  const memberState = state[toFollowId];
  const member = isDefined(memberState)
    ? {
        ...memberState,
        relationship,
      }
    : undefined;
  if (isDefined(listGroup) && isDefined(member)) {
    member.listGroup = listGroup;
  }
  return member;
};

const defaultState: MemberState = {};

export type MemberState = Record<string, Member | undefined>;

export type MemberRequestAction = {
  type: MembersActionType.MemberRequest;
  payload: { userIdOrUsername: string };
};
export type MemberSuccessAction = {
  type: MembersActionType.MemberSuccess;
  payload: Member;
};

export type MemberFollowAction = {
  type: MembersActionType.FollowRequest;
  payload: { userId: string };
};

export type MemberUpdateAction = ReturnType<typeof updateMemberInfo>;

export type MemberListSuccessAction = {
  type: MembersActionType.MembersListSuccess;
  payload: Member[];
};

export type ChangeRelationshipRequestAction = {
  type: MembersActionType.ToggleFollowRequest;
  payload: { namespace: string };
  meta: { source: string };
};

export type ChangeRelationshipSuccessAction = {
  type: MembersActionType.ToggleFollowSuccess;
  payload: {
    relationship: MemberRelationship;
    selfId: string;
    followedId: string;
    relationshipChange: RelationshipChange;
  };
};

export type UpdateMemberShareChallengeFinisher = {
  type: MembersActionType.UpdateMemberShareChallengeFinisher;
  payload: { shareCorporateWellnessCompletion: boolean | null };
};

type FailureAction = FailAction<MembersActionType.RequestFailure>;

type Action =
  | MemberRequestAction
  | ChangeRelationshipRequestAction
  | MemberSuccessAction
  | MemberListSuccessAction
  | ChangeRelationshipSuccessAction
  | FailureAction
  | AvatarUploaderSuccessAction
  | ApproveFollowerSuccessAction
  | RejectFollowerSuccessAction
  | RemoveFollowerSuccessAction
  | UpdateMemberShareChallengeFinisher
  | ReceiveDefaultAvatarAction;
