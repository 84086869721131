import { transparentize } from 'polished';
import React from 'react';
import styled from 'styled-components';
import type { Location } from '@peloton/redux';
import { getLocation } from '@peloton/redux';
import { hover, media } from '@peloton/styles';
import { track } from '@engage/analytics';
import { superRedA11y, slate1, gray4, white, gray1 } from '@engage/colors';
import { useReduxAction, useReduxState } from '@engage/redux';
import { spaces } from '@engage/styles';
import { label12 } from '@engage/typography/font-styles';
import { TagsCopy, Tags } from '@members/copy';
import { trackInitiatedTagSearch } from '../analytics';
import { BrowseTagsIcon } from '../icons/BrowseTagsIcon';
import { MyTagsIcon } from '../icons/MyTagsIcon';
import { SearchAndCreateIcon } from '../icons/SearchAndCreateIcon';
import { TagModalView } from '../shared';
import { TagsContext } from '../TagsContext';
import {
  ScrollContext,
  HEADER_MOBILE_HEIGHT,
  HEADER_TABLET_HEIGHT,
  PERSISTENT_NAV_BAR_HEIGHT,
} from './ScrollContext';

export const PERSISTENT_NAV_BAR_VIEWS = [
  TagModalView.MyTags,
  TagModalView.SearchAndCreate,
  TagModalView.BrowseTags,
];

export const PersistentNavBar: React.FC<
  React.PropsWithChildren<{
    selectedView: TagModalView;
  }>
> = ({ selectedView }) => {
  const { navBarRef } = React.useContext(ScrollContext);
  return (
    <Container ref={navBarRef}>
      <InnerContainer>
        {PERSISTENT_NAV_BAR_VIEWS.map(view => (
          <NavItem key={view} isSelected={view === selectedView} view={view} />
        ))}
      </InnerContainer>
    </Container>
  );
};

export const NavItem: React.FC<
  React.PropsWithChildren<{ isSelected: boolean; view: TagModalView }>
> = ({ isSelected, view }) => {
  const { setView, lastActiveBrowseCategory } = React.useContext(TagsContext);
  const trackAction = useReduxAction(track);
  const location: Location = useReduxState(getLocation);
  const source = location.state?.source ?? 'Direct Link';

  const onClick = () => {
    setView(view, undefined, lastActiveBrowseCategory ?? 'TRENDING');
    if (view === TagModalView.SearchAndCreate) {
      trackAction(trackInitiatedTagSearch(source));
    }
  };
  return (
    <ItemContainer data-test-id={`${view}NavBarButton`} onClick={onClick}>
      <Icon as={toViewIcon(view)} $isSelected={isSelected} />
      <ViewName $isSelected={isSelected}>{toViewName(view)}</ViewName>
    </ItemContainer>
  );
};

const toViewIcon = (view: TagModalView) => {
  switch (view) {
    case TagModalView.MyTags:
      return MyTagsIcon;
    case TagModalView.SearchAndCreate:
      return SearchAndCreateIcon;
    case TagModalView.BrowseTags:
    default:
      return BrowseTagsIcon;
  }
};

const toViewName = (view: TagModalView) => {
  switch (view) {
    case TagModalView.MyTags:
      return <TagsCopy id={Tags.MyTags} />;
    case TagModalView.SearchAndCreate:
      return <TagsCopy id={Tags.SearchOrCreate} />;
    case TagModalView.BrowseTags:
    default:
      return <TagsCopy id={Tags.Explore} />;
  }
};

const Container = styled.div`
  z-index: 3;
  position: absolute;
  top: ${HEADER_MOBILE_HEIGHT}px;
  height: fit-content;
  min-height: ${PERSISTENT_NAV_BAR_HEIGHT}px;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${white};
  border-bottom: 1px solid ${transparentize(0.75, gray4)};
  padding-bottom: 12px;
  ${media.iPhone6`
    height: ${PERSISTENT_NAV_BAR_HEIGHT}px;
  `}

  ${media.tablet`
    top: ${HEADER_TABLET_HEIGHT}px;
  `}
`;

const InnerContainer = styled.div`
  width: 350px;
  display: flex;
  justify-content: space-between;
  padding-top: ${spaces.xSmall}px;
  padding-left: ${spaces.small}px;
`;

const ItemContainer = styled.button`
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  min-width: 98px;
  padding: ${spaces.xxxSmall}px;
  border-radius: ${spaces.xxxxSmall}px;

  ${hover`
    transition: background-color 250ms ease;
    background-color: ${gray1};
  `}
`;

const Icon = styled.div<{ $isSelected: boolean }>`
  height: 32px;
  width: 32px;
  stroke: ${({ $isSelected }) => ($isSelected ? superRedA11y : slate1)};
`;

const ViewName = styled.div<{ $isSelected: boolean }>`
  ${label12}
  color: ${({ $isSelected }) => ($isSelected ? superRedA11y : slate1)};
  margin-top: ${spaces.xxxSmall}px;
`;
ViewName.displayName = 'ViewName';
