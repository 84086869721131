import { head, values } from 'ramda';
import { isDefined } from '@peloton/types';
import { ME } from './models';
import type { Member } from './models/Member';
import type { MemberState } from './redux';

export const MEMBERS_NAMESPACE = 'members';
export type MemberSelectorState = {
  members: MemberState;
};

const lookupByUsername = (state: MemberState, username: string) =>
  head(
    values(state).filter(
      member => (member?.username.toLowerCase() ?? '') === username.toLowerCase(),
    ),
  );

export const getMember = (state: MemberSelectorState, usernameOrId: string = ME) => {
  if (isDefined(state[MEMBERS_NAMESPACE][usernameOrId])) {
    return state[MEMBERS_NAMESPACE][usernameOrId];
  } else {
    const meIfSelfIdOrUsername = getMeIfSelfIdOrUsername(
      state[MEMBERS_NAMESPACE],
      usernameOrId,
    );
    return meIfSelfIdOrUsername
      ? meIfSelfIdOrUsername
      : lookupByUsername(state[MEMBERS_NAMESPACE], usernameOrId);
  }
};

export const getUsername = (
  state: MemberSelectorState,
  idOrName: string | undefined,
): string | undefined => {
  const member = getMember(state, idOrName);
  return member?.username;
};
``;

const getMeIfSelfIdOrUsername = (state: MemberState, usernameOrId: string = ME) => {
  if (
    state[ME]?.id === usernameOrId ||
    state[ME]?.username.toLowerCase() === usernameOrId.toLowerCase()
  ) {
    return state[ME];
  }
  return undefined;
};

export const isMemberPrivate = (
  state: MemberSelectorState,
  idOrName: string | undefined,
): boolean | undefined => {
  const member = getMember(state, idOrName);
  return member?.isProfilePrivate;
};

export type TopNavUserInfo = {
  imageUrl: string;
  username: string;
};

export const getTopNavUserInfo = (state: MemberSelectorState) => {
  const me = state[MEMBERS_NAMESPACE][ME];
  return isDefined(me)
    ? {
        imageUrl: me.imageUrl,
        username: me.username,
      }
    : undefined;
};

export const getMembersList = (state: MemberSelectorState, ids: string[] = []) =>
  ids.map(id => getMember(state, id)).filter(Boolean) as Member[];
