import { transparentize } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { defaultTransition } from '@peloton/styles';
import { white } from '@engage/colors';
import { spaces } from '@engage/styles';
import { Button2Small } from '@members/buttons';
import { useTags, Tags, TagsCopy } from '@members/copy';
import type { AddTagToUserMutationVariables } from '@members/graphql-swr/schemas/tags/mutations/AddTagToUser.generated';
import type { RemoveTagFromUserMutationVariables } from '@members/graphql-swr/schemas/tags/mutations/RemoveTagFromUser.generated';
import type { SetTagAsPrimaryMutationVariables } from '@members/graphql-swr/schemas/tags/mutations/SetTagAsPrimary.generated';
import { LoadingSpinner } from '@members/loading';
import { Check } from '../icons/Check';
import { Plus } from '../icons/Plus';
import { StarOutline } from '../icons/StarOutline';

export const ICON_SIZE = 32;

export const CreateTagButton: React.FC<
  React.PropsWithChildren<{
    addTagToUser: (input?: AddTagToUserMutationVariables) => void;
    tagName: string;
    isLoading: boolean;
  }>
> = ({ addTagToUser, tagName, isLoading }) => (
  <StyledCreateTagButton
    onClick={() =>
      addTagToUser({
        input: { tagName },
      })
    }
    aria-label={useTags(Tags.Create)}
    data-test-id="createTagButton"
  >
    {isLoading ? (
      <StyledLoadingSpinner
        isLoading={true}
        size={20}
        fill={transparentize(0.15, 'white')}
      />
    ) : (
      <TagsCopy id={Tags.Create} />
    )}
  </StyledCreateTagButton>
);

export const AddTagButton: React.FC<
  React.PropsWithChildren<{
    addTagToUser: (input?: AddTagToUserMutationVariables) => void;
    tagName: string;
  }>
> = ({ addTagToUser, tagName }) => (
  <Button
    onClick={() =>
      addTagToUser({
        input: { tagName },
      })
    }
    aria-label={useTags(Tags.AddCta)}
    data-test-id="addTagCardButton"
  >
    <PlusIcon bkgColor={white} fillOpacity={0.15} />
  </Button>
);

export const SetPrimaryTagButton: React.FC<
  React.PropsWithChildren<{
    setTagAsPrimary: (input?: SetTagAsPrimaryMutationVariables) => void;
    tagName: string;
  }>
> = ({ setTagAsPrimary, tagName }) => (
  <Button
    onClick={() => {
      setTagAsPrimary?.({ input: { tagName } });
    }}
    aria-label={useTags(Tags.SetPrimaryCta)}
    data-test-id="setPrimaryTagButton"
  >
    <StarOutlineIcon />
  </Button>
);

export const RemoveTagButton: React.FC<
  React.PropsWithChildren<{
    removeTagFromUser: (input?: RemoveTagFromUserMutationVariables) => void;
    tagName: string;
  }>
> = ({ removeTagFromUser, tagName }) => (
  <Button
    onClick={() =>
      removeTagFromUser({
        input: {
          tagName,
        },
      })
    }
    aria-label={useTags(Tags.RemoveCta)}
    data-test-id="removeTagButton"
  >
    <CheckIcon />
  </Button>
);
const Button = styled.button`
  cursor: pointer;
  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
  z-index: 1;
`;

const PlusIcon = styled(Plus)`
  ${defaultTransition('opacity')}
  opacity: 0.85;
  &:hover {
    opacity: 1;
  }
`;
const StarOutlineIcon = styled(StarOutline)`
  height: ${ICON_SIZE}px;
  width: ${ICON_SIZE}px;

  path {
    ${defaultTransition('opacity')}
    opacity: 0;
  }

  &:hover {
    path {
      opacity: 0.35;
    }
  }
`;

const CheckIcon = styled(Check)`
  ${defaultTransition('opacity')}
  &:hover {
    opacity: 0.85;
  }
`;

const StyledCreateTagButton = styled(Button2Small)`
  width: 100px;
  ${defaultTransition('opacity')}
  opacity: 0.85;
  &:hover {
    opacity: 1;
  }
`;

export const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin-top: ${spaces.xxxxSmall}px;
`;

StyledLoadingSpinner.displayName = 'StyledLoadingSpinner';
