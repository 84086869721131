import { path, isEmpty } from 'ramda';
import type { Reducer } from 'redux';
import type { TypedAction } from '@peloton/redux';
import type { FetcherSelectorState } from '@peloton/redux-fetch';
import { isDefined } from '@peloton/types';
import type { AchievementTemplate } from '@engage/achievements';
import type { MemberSelectorState } from '@engage/members/selectors';
import { getMember } from '@engage/members/selectors';
import { getFitnessDiscipline } from '@engage/metadata';
import type { ActiveDayHistory } from '@engage/overview-ui';
import type { Subscription } from '@engage/subscriptions';
import { isDeviceSub } from '@engage/subscriptions';
import type { Overview } from '../../models/Overview';
import type { PersonalRecords } from '../../models/PersonalRecords';
import type { Streaks } from '../../models/Streaks';
import type { WorkoutCounts } from '../../models/WorkoutCounts';
import { toWorkoutCountsView } from '../../workoutCountsMappers';
import type { OverviewSelectorState, State } from './types';

type Action =
  | TypedAction<ReturnType<typeof loadOverviewSuccess>, OverviewActionType.Success>
  | TypedAction<
      ReturnType<typeof loadActiveDayHistorySuccess>,
      OverviewActionType.ActiveDayHistorySuccess
    >;

export enum OverviewActionType {
  Success = 'pelo/overview/RequestSuccess',
  ActiveDayHistorySuccess = '@members/pg-preferences/overview/ACTIVE_DAY_HISTORY',
}

export const OVERVIEW_NAMESPACE = 'overview';

const RESET_PR_VALUE = -1;

// reducers
export const overviewReducer: Reducer<State> = (state = {}, action: Action) => {
  switch (action.type) {
    case OverviewActionType.Success:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          achievements: action.payload.achievements,
          id: action.payload.id,
          personalRecords: action.payload.personalRecords,
          workoutCounts: action.payload.workoutCounts,
          streaks: action.payload.streaks,
        },
      };
    case OverviewActionType.ActiveDayHistorySuccess:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          activeDayHistory: action.payload.activeDayHistory,
        },
      };
    default:
      return state;
  }
};

// selectors
export const getWorkoutCounts = (
  state: OverviewSelectorState & MemberSelectorState,
  usernameOrId?: string,
) => {
  const member = getMember(state, usernameOrId);
  if (!isDefined(member)) {
    return undefined;
  }
  const counts = path<WorkoutCounts>(['overview', member.id, 'workoutCounts'], state);

  return isDefined(counts) ? toWorkoutCountsView(counts, member) : undefined;
};

export const getAchievementsForOverview = (
  state: OverviewSelectorState & MemberSelectorState,
  usernameOrId?: string,
) => {
  const member = getMember(state, usernameOrId);
  if (!isDefined(member)) {
    return undefined;
  }
  const achievements = path<AchievementTemplate[]>(
    ['overview', member.id, 'achievements'],
    state,
  );

  return achievements;
};

export const getActiveDayHistory = (state: OverviewSelectorState, userId: string) =>
  path<ActiveDayHistory>(['overview', userId, 'activeDayHistory'], state) ?? [];

export const getStreaks = (state: OverviewSelectorState, userId: string) =>
  path<Streaks>(['overview', userId, 'streaks'], state) ?? {
    bestWeekly: 0,
    currentWeekly: 0,
    startDateOfCurrentWeekly: 0,
  };

// Reset PRs remain in the response but with a negative output value
export const getFilteredPersonalRecords = (personalRecords: PersonalRecords) =>
  personalRecords.map(category => ({
    ...category,
    records: category.records.filter(record => record.value !== RESET_PR_VALUE),
  }));

export const getPersonalRecords = (
  state: OverviewSelectorState & MemberSelectorState & FetcherSelectorState,
  usernameOrId?: string,
) => {
  const member = getMember(state, usernameOrId);
  if (!isDefined(member)) {
    return [];
  }
  const counts =
    path<PersonalRecords>(['overview', member.id, 'personalRecords'], state) ?? [];
  const filteredCounts = getFilteredPersonalRecords(counts);

  return filteredCounts
    .filter(category => category.records.length > 0)
    .map(category => {
      const fitnessDiscipline = getFitnessDiscipline(state, category.slug);
      return {
        ...category,
        name: fitnessDiscipline && fitnessDiscipline.name,
      };
    });
};

export const hasDeviceSub = (subs: Subscription[]) => {
  return !isEmpty(subs.filter(sub => isDeviceSub(sub)));
};

// actions
export const loadOverviewSuccess = (overview: Overview) => ({
  type: OverviewActionType.Success,
  payload: overview,
});

export const loadActiveDayHistorySuccess = (
  userId: string,
  activeDayHistory: ActiveDayHistory,
) => ({
  type: OverviewActionType.ActiveDayHistorySuccess,
  payload: {
    id: userId,
    activeDayHistory,
  },
});

const REQUIRED_OVERVIEW_KEYS = [
  'achievements',
  'activeDayHistory',
  'personalRecords',
  'workoutCounts',
].filter(Boolean);

const hasRequiredOverviewProps = (overview: Overview): boolean =>
  REQUIRED_OVERVIEW_KEYS.every(k => Boolean(overview[k]));

export const isOverviewLoaded = (
  state: OverviewSelectorState & MemberSelectorState,
  usernameOrId?: string,
) => {
  const member = getMember(state, usernameOrId);
  if (!isDefined(member)) {
    return false;
  }
  const memberOverview = path<Overview>(['overview', member.id], state);
  return (
    isDefined(memberOverview) && hasRequiredOverviewProps(memberOverview as Overview)
  );
};
