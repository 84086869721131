import React from 'react';
import styled, { css } from 'styled-components';
import { Device } from '@peloton/browser';
import { media } from '@peloton/styles';
import { spaces } from '@engage/styles';
import { header6Large } from '@engage/typography';
import { AppDownload, AppDownloadCopy, useAppDownload } from '@members/copy';
import { Rating, DownloadCTA, useAnalytics, toApp, App } from '@members/get-apps';
import { Image } from '@members/images';
import { AnalyticsSource } from './analytics';
import { android, ios } from './img';
import { Title, Divider, cardStyles, backgroundStyles } from './shared';

type Props = {
  device: Device;
  isOpen: boolean;
};

const GetAppFromDevice: React.FC<React.PropsWithChildren<Props>> = ({
  device,
  isOpen,
}) => {
  const [displayNameId, downloadLinkId] =
    device === Device.IOS
      ? [AppDownload.IOS, AppDownload.AppStoreIOS]
      : [AppDownload.Android, AppDownload.AppStoreAndroid];

  const downloadLink = useAppDownload(downloadLinkId);
  const displayName = useAppDownload(displayNameId);
  const app = toApp(device);

  const [toOnDownloadClick] = useAnalytics([app], AnalyticsSource.MoreMenu, isOpen);
  const onDownloadClick = () => toOnDownloadClick(app);

  return (
    <ModalContent>
      <Title>
        <AppDownloadCopy id={AppDownload.Title} values={{ displayName }} />
      </Title>
      <StyledDivider />
      <InnerContent>
        <StyledImage
          src={toImageUrl(app)}
          alt=""
          breakpointOptions={{
            mobile: { width: 559 },
            tablet: { width: 456 },
          }}
        />
        {device === Device.IOS ? <Rating darkStars /> : null}
        <Message>
          <AppDownloadCopy id={AppDownload.AppMessage} values={{ displayName }} />
        </Message>
        <StyledDownloadCTA
          href={downloadLink}
          onClick={onDownloadClick}
          rel="noopener"
          target="_blank"
        >
          <AppDownloadCopy id={AppDownload.DownloadCTA} />
        </StyledDownloadCTA>
      </InnerContent>
    </ModalContent>
  );
};

const StyledDivider = styled(Divider)`
  margin-bottom: ${spaces.xLarge}px;
`;

const toImageUrl = (app: App) => {
  const osImage = app === App.IOS ? ios : android;
  if (typeof osImage === 'string') {
    return osImage;
  }
  return (osImage as { src: string }).src;
};

const ModalContent = styled.div`
  ${backgroundStyles}
`;

const InnerContent = styled.div`
  ${cardStyles}

  ${media.tablet`
    // tslint:disable-next-line:inline-spaces-values
    margin: 0 ${spaces.xxxLarge}px 170px ${spaces.xxxLarge}px;
  `}
`;

const imageStyles = css`
  border-radius: 5px 5px 0 0;
  height: unset;
  width: 100%;
`;

const StyledImage = styled(Image)`
  ${imageStyles}
  margin-bottom: ${spaces.large}px;
`;

const Message = styled.div`
  ${header6Large}
  // tslint:disable-next-line:inline-spaces-values
  margin: 5px ${spaces.large}px;
  text-align: center;

  ${media.tablet`
    // tslint:disable-next-line:inline-spaces-values
    margin: 5px 30px 15px 30px;
  `}
`;

const StyledDownloadCTA = styled(DownloadCTA)`
  // tslint:disable-next-line:inline-spaces-values
  margin: 14px auto 26px auto;
`;

export default GetAppFromDevice;
