import { configureErrorHandler } from '@peloton/error-reporting';
import {
  consoleErrorReporter,
  datadogBrowserErrorReporter,
} from '@peloton/error-reporting/reporters';
import { commitSHA } from '@members/env';
import * as Env from '@members/env';

const isLocalEnv = Env.isLocalEnv();
const isProdEnv = Env.isProdEnv();
export const setupErrorHandler = (env: any) =>
  configureErrorHandler(
    [
      ...(isProdEnv ? [] : [consoleErrorReporter]),
      ...(isLocalEnv ? [] : [datadogBrowserErrorReporter]),
    ],
    {
      environment: env.error,
      release: `web-engage@${commitSHA}`,
    },
  );
