import React from 'react';
import styled from 'styled-components';
import { hoverTransition } from '@peloton/styles';
import { white, slate1 } from '@engage/colors';
import { spaces } from '@engage/styles';
import { label12, label16Caps } from '@engage/typography';
import { TagsCopy, Tags } from '@members/copy';
import { TagBrowseCategorySlug } from '@members/graphql-swr/types.generated';
import { AddTagsPlus } from '../icons/AddTagsPlus';
import { TagModalView } from '../shared';
import { TagsContext } from '../TagsContext';

export const SubTextContainer: React.FC<
  React.PropsWithChildren<{ remainingTagCount: number }>
> = ({ remainingTagCount }) => (
  <SubText>
    {remainingTagCount > 0 ? (
      <TagsCopy id={Tags.RemainingTagsMessage} values={{ remainingTagCount }} />
    ) : (
      <TagsCopy id={Tags.MaximumTagsMessage} />
    )}
  </SubText>
);

export const AddTags: React.FC<
  React.PropsWithChildren<{ remainingTagCount: number }>
> = ({ remainingTagCount }) => {
  const { setView } = React.useContext(TagsContext);
  return (
    <Container
      onClick={() =>
        setView(TagModalView.BrowseTags, undefined, TagBrowseCategorySlug.Trending)
      }
      data-test-id="myTagsAddTagsButton"
    >
      <MainText>
        <PlusIcon />
        <TagsCopy id={Tags.AddTags} />
      </MainText>
      <SubTextContainer remainingTagCount={remainingTagCount} />
    </Container>
  );
};

const Container = styled.button`
  height: 110px;
  width: 100%;
  border-radius: ${spaces.xxSmall}px;
  background-color: ${white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: solid 1px rgba(138, 141, 145, 0.2);
  margin-bottom: ${spaces.small}px;

  ${hoverTransition({
    property: 'background-color',
    from: white,
    to: '#f3f3f3',
  })}
`;

const MainText = styled.div`
  ${label16Caps}
  line-height: 20px;
  color: ${slate1};
  display: flex;
`;

const PlusIcon = styled(AddTagsPlus)`
  height: ${spaces.large}px;
  width: ${spaces.large}px;
  margin-right: ${spaces.xSmall}px;
`;

const SubText = styled.div`
  ${label12}
  color: ${slate1};
  margin-top: ${spaces.small}px;
`;
