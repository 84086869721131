import { contains, isEmpty } from 'ramda';
import { toWWWLink } from '@peloton/external-links';

import type { ApiInstructor } from './api';
import { FitnessDisciplineSlug } from './models';
import type { Instructor } from './models/Instructor';

export const toInstructor = (apiInstructor: ApiInstructor): Instructor => ({
  aboutImageUrl: apiInstructor.aboutImageUrl,
  darkAboutImageUrl: apiInstructor.jumbotronUrlDark,
  id: apiInstructor.id,
  imageUrl: apiInstructor.imageUrl,
  isVisible: apiInstructor.isVisible,
  linkUrl: toLinkUrl(apiInstructor.username, apiInstructor.fitnessDisciplines),
  name: apiInstructor.name,
  username: apiInstructor.username,
});

export const toLinkUrl = (username: string, fitnessDisciplines: string[]) => {
  let discipline = DisciplineCategory.Tread;
  if (
    contains(FitnessDisciplineSlug.Cycling, fitnessDisciplines) ||
    isEmpty(fitnessDisciplines)
  ) {
    discipline = DisciplineCategory.Bike;
  }
  return toWWWLink(`/${discipline}/instructors/${username}`);
};

enum DisciplineCategory {
  Bike = 'bike',
  Tread = 'tread',
}
