import { isDefined } from '@peloton/types';
import type { UnwrappedOptionalVariableType } from '@engage/optimizely';
import type { Toggles, VariableConfig } from './models';
import type { State } from './state/toggleState';

export const FEATURE_TOGGLE_NAMESPACE = 'feature-toggles';
export type FeatureToggleState = { [FEATURE_TOGGLE_NAMESPACE]: State };

export type PlatformSpecificGetFeatureToggle<Toggle extends string> = (
  state: FeatureToggleState,
  toggle: Toggle,
) => Record<string, boolean>[Toggle];

export type PlatformSpecificSetFeatureToggle<Toggle extends string> = (
  toggle: Toggle,
  state: boolean,
) => void;

export type VariablesForFeature<
  Config extends VariableConfig,
  Feature extends keyof Config
> = Feature extends keyof Config
  ? {
      [K in Config[Feature][number]['key']]: UnwrappedOptionalVariableType<
        Extract<Config[Feature][number], { key: K }>
      >;
    }
  : never;

type ValueTypesOf<T> = T extends { [K in keyof T]: infer U } ? U : never;

/**
 * Because Typescript does not allow calls on unions of dissimilar signatures,
 * this helper extracts all variable types and provides a single union.
 */
export type VariablesForFeatures<
  Config extends VariableConfig,
  Feature extends keyof Config
> = Feature extends keyof Config
  ? ValueTypesOf<
      {
        [K in Config[Feature][number]['key']]: Extract<
          Config[Feature][number],
          { key: K }
        >;
      }
    >
  : never;

export type PlatformSpecificGetVariables<Config extends VariableConfig> = <
  Feature extends keyof Config
>(
  state: FeatureToggleState,
  feature: Feature,
) => VariablesForFeature<Config, Feature>;

/** This should be imported into your platform and typed with your feature toggles. See `@members/feature-toggles` */
export const getFeatureToggle: PlatformSpecificGetFeatureToggle<keyof Toggles> = <
  Toggle extends keyof Toggles
>(
  state: FeatureToggleState,
  toggle: Toggle,
) => state[FEATURE_TOGGLE_NAMESPACE].toggles[toggle]?.value ?? false;

export const getFeatureToggles = (state: FeatureToggleState) =>
  state[FEATURE_TOGGLE_NAMESPACE].toggles;

export const getVariables = (state: FeatureToggleState) =>
  state[FEATURE_TOGGLE_NAMESPACE].variables;

export const isRemoteTogglesLoaded = (state: FeatureToggleState) =>
  state[FEATURE_TOGGLE_NAMESPACE].isRemoteLoaded;

export const getEnabledFeatures = (state: FeatureToggleState) =>
  Object.keys(state[FEATURE_TOGGLE_NAMESPACE].toggles).reduce(
    (enabledToggles, toggle) => {
      const overridableToggle = state[FEATURE_TOGGLE_NAMESPACE].toggles[toggle];
      if (isDefined(overridableToggle)) {
        const { originalValue, value } = overridableToggle;
        if (value || originalValue) {
          enabledToggles.push(toggle);
        }
      }

      return enabledToggles;
    },
    [] as string[],
  );

export const getVariablesForFeature = (state: FeatureToggleState, feature: string) => {
  const vars = getVariables(state)[feature];
  const enabled = getFeatureToggle(state, feature);
  return Object.keys(vars ?? {}).reduce((obj, key) => {
    obj[key] = enabled
      ? vars?.[key]?.value ?? vars?.[key]?.defaultValue
      : vars?.[key]?.defaultValue;
    return obj;
  }, {} as any);
};
