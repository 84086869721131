import { stringify } from 'querystring';
import React, { useState } from 'react';
import styled from 'styled-components';
import useSWRImmutable from 'swr/immutable';
import { history } from '@peloton/redux';
import { media, hover } from '@peloton/styles';
import { track } from '@engage/analytics';
import { BrowseCategorySlug } from '@engage/browse-categories';
import type { TopNavUserInfo } from '@engage/members';
import { useReduxAction } from '@engage/redux';
import { spaces } from '@engage/styles';
import { TopNav, useTopNav, useActivityFeed, ActivityFeed } from '@members/copy';
import { StackedClassesFetchersGQL } from '@members/data-fetch';
import { useFeatureToggle, Feature } from '@members/feature-toggles';
import {
  StackedPlayAlert,
  CalendarAlert,
  StackedPlay,
  Calendar,
  Bookmark,
} from '@members/icons';
import {
  bookmarkButtonClassName,
  bookmarkParentClassName,
  scheduleButtonClassName,
  scheduleParentClassName,
  stacksButtonClassName,
  stacksParentClassName,
  searchParentClassName,
  searchButtonClassName,
} from '@members/onboarding-tooltips/classNames';
import FeedNotificationsContainer from '@members/pg-feed/components/FeedNotificationsContainer';
import SearchIcon from '@members/pg-library/icons/SearchIcon';
import { toLibraryCategoryRoute } from '@members/pg-library/urls';
import { YOUR_SCHEDULE_ROUTE } from '@members/schedule/urls';
import { Source } from '@members/stacks/analytics';
import { TooltipTarget } from '@members/tooltips';
import { focusLinkStyles, hoverLinkStyles, MainLink } from '../../../sharedLinkStyles';
import {
  ANALYTICS_SOURCE,
  trackTappedHomescreenTopNavigation,
  trackViewedNavItem,
} from '../../analytics';
import { openStackFromNav } from '../StackFromNav';
import { usePendingInviteSetup } from '../TopNavLinks';
import { NotificationsBadge } from './NotificationsBadge';
import MiniNavTooltip from './ToolTip';

const bookmarkMap = { ['is_favorite_ride']: JSON.stringify(['true']) };

type Props = {
  user: TopNavUserInfo;
};
export const MiniNavIcons: React.FC<React.PropsWithChildren<Props>> = ({
  user,
}: Props) => {
  const trackAnalytics = useReduxAction(track);
  const handleStackClick = useReduxAction(() => openStackFromNav(Source.Navigation));
  const [isExpanded, setIsExpanded] = useState(false);
  const { valueToShow } = usePendingInviteSetup();
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const bookmarkCopy = useTopNav(TopNav.Bookmarks);
  const stackedPlayCopy = useTopNav(TopNav.StackedClasses);
  const calendarCopy = useTopNav(TopNav.YourSchedule);
  const notificationsCopy = useTopNav(TopNav.Notifications);
  const searchCopy = useActivityFeed(ActivityFeed.Search);
  const { data } = useSWRImmutable(...StackedClassesFetchersGQL.ViewUserStackQuery({}));

  const numStackedClasses = data?.viewUserStack?.numClasses ?? -1;
  const navUpdatesEnabled = useFeatureToggle(Feature.Navupdates);

  return (
    <MiniNavIconContainer>
      {navUpdatesEnabled ? (
        <>
          <div className={searchParentClassName} data-test-id="navSearchIconContainer">
            <TooltipTarget tooltipId={searchCopy} isExpanded={isExpanded}>
              <LinkIconContainer
                to={{
                  pathname: toLibraryCategoryRoute(BrowseCategorySlug.All),
                  search: `?${stringify(bookmarkMap)}`,
                  state: { source: ANALYTICS_SOURCE },
                }}
                data-test-id="searchLink"
                onClick={event => {
                  event.preventDefault();
                  history.push('/search?c=all', {
                    source: ANALYTICS_SOURCE,
                    state: {
                      from: history.location.pathname,
                    },
                  });
                }}
                aria-label={searchCopy}
                className={searchButtonClassName}
              >
                <SearchIcon />
              </LinkIconContainer>
            </TooltipTarget>
          </div>
          <MiniNavTooltip
            tooltipId={searchCopy}
            setIsExpanded={setIsExpanded}
          ></MiniNavTooltip>
        </>
      ) : null}
      <div className={bookmarkParentClassName} data-test-id="navBookmarkIconContainer">
        <TooltipTarget tooltipId={bookmarkCopy} isExpanded={isExpanded}>
          <LinkIconContainer
            to={{
              pathname: toLibraryCategoryRoute(BrowseCategorySlug.All),
              search: `?${stringify(bookmarkMap)}`,
              state: { source: ANALYTICS_SOURCE },
            }}
            data-test-id="bookmarkLink"
            onClick={event => {
              event.preventDefault();

              trackAnalytics(trackViewedNavItem('Bookmarks'));
              trackAnalytics(
                trackTappedHomescreenTopNavigation('Bookmarks', 'Navigation'),
              );

              history.push(
                toLibraryCategoryRoute(BrowseCategorySlug.All) +
                  `?${stringify(bookmarkMap)}`,
                {
                  source: ANALYTICS_SOURCE,
                },
              );
            }}
            aria-label={bookmarkCopy}
            className={bookmarkButtonClassName}
          >
            <Bookmark />
          </LinkIconContainer>
        </TooltipTarget>
      </div>
      <MiniNavTooltip
        tooltipId={bookmarkCopy}
        setIsExpanded={setIsExpanded}
      ></MiniNavTooltip>
      <div className={stacksParentClassName} data-test-id="navStacksIconContainer">
        <TooltipTarget tooltipId={stackedPlayCopy} isExpanded={isExpanded}>
          <ButtonIconContainer
            data-test-id="stacksLink"
            onClick={() => {
              trackAnalytics(trackTappedHomescreenTopNavigation('Stacks', 'Navigation'));
              handleStackClick();
            }}
            aria-label={stackedPlayCopy}
            className={stacksButtonClassName}
          >
            {numStackedClasses > 0 ? <StackedPlayAlert /> : <StackedPlay />}
          </ButtonIconContainer>
        </TooltipTarget>
      </div>
      <MiniNavTooltip
        tooltipId={stackedPlayCopy}
        setIsExpanded={setIsExpanded}
      ></MiniNavTooltip>
      <div className={scheduleParentClassName} data-test-id="navScheduleIconContainer">
        <TooltipTarget tooltipId={calendarCopy} isExpanded={isExpanded}>
          <LinkIconContainer
            to={{ pathname: YOUR_SCHEDULE_ROUTE, state: { source: ANALYTICS_SOURCE } }}
            data-test-id="yourScheduleLink"
            onClick={event => {
              event.preventDefault();
              trackAnalytics(
                trackTappedHomescreenTopNavigation('YourSchedule', 'Navigation'),
              );
              history.push(YOUR_SCHEDULE_ROUTE, {
                source: ANALYTICS_SOURCE,
              });
            }}
            aria-label={calendarCopy}
            className={scheduleButtonClassName}
          >
            {valueToShow > 0 ? <CalendarAlert /> : <Calendar />}
          </LinkIconContainer>
        </TooltipTarget>
      </div>
      <MiniNavTooltip
        tooltipId={calendarCopy}
        setIsExpanded={setIsExpanded}
      ></MiniNavTooltip>
      <TooltipTarget tooltipId={notificationsCopy} isExpanded={isExpanded}>
        <ButtonIconContainer
          data-test-id="notificationsLink"
          onClick={() => setIsNotificationsOpen(!isNotificationsOpen)}
          aria-label={notificationsCopy}
          aria-expanded={isNotificationsOpen}
          aria-controls="Notifications"
        >
          <NotificationsBadge isNotificationsOpen={isNotificationsOpen} />
        </ButtonIconContainer>
      </TooltipTarget>
      <MiniNavTooltip
        tooltipId={notificationsCopy}
        setIsExpanded={setIsExpanded}
      ></MiniNavTooltip>
      <FeedNotificationsContainer
        isOpen={isNotificationsOpen}
        setIsOpen={setIsNotificationsOpen}
      />
    </MiniNavIconContainer>
  );
};

const MiniNavIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
`;

const ButtonIconContainer = styled.button`
  cursor: pointer;
  align-items: center;
  display: flex;
  padding: ${spaces.small}px;
  ${hover`
    transition: background-color 250ms ease;
    border-radius: 9999px;
  `}
  ${media.tablet`
    ${hoverLinkStyles}
    ${focusLinkStyles}
  `}
`;

const LinkIconContainer = styled(MainLink)`
  cursor: pointer;
  align-items: center;
  display: flex;
  padding: ${spaces.small}px;
  ${hover`
    transition: background-color 250ms ease;
    border-radius: 9999px;
  `}
  ${media.tablet`
    ${hoverLinkStyles}
    `}
`;
