import React from 'react';
import styled from 'styled-components';
import { paleBlue, blue, slate3, slate1, gray1, white } from '@engage/colors';
import { spaces } from '@engage/styles';
import { label16Caps, title24 } from '@engage/typography';
import { Button1Large, Button2Large } from '@members/buttons';
import { TagsCopy, Tags } from '@members/copy';
import { HashTag } from '../icons/HashTag';
import { TagModalView } from '../shared';
import { TagsContext } from '../TagsContext';

export const NoTagsView: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { setView } = React.useContext(TagsContext);
  return (
    <Container>
      <HashIcon topHashColor={paleBlue} bottomHashColor={blue} />
      <AddTagsMessage data-test-id="addTagsLongMessage">
        <TagsCopy id={Tags.AddTagsLongMessage} unorphanCount={2} />
      </AddTagsMessage>
      <ExploreButton
        onClick={() => setView(TagModalView.BrowseTags)}
        data-test-id="exploreTagsButton"
      >
        <TagsCopy id={Tags.Explore} />
      </ExploreButton>
      <SearchOrCreateButton
        onClick={() => setView(TagModalView.SearchAndCreate)}
        data-test-id="searchOrCreateTagsButton"
      >
        <TagsCopy id={Tags.SearchOrCreate} />
      </SearchOrCreateButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: ${spaces.huge}px 0;
`;

const HashIcon = styled(HashTag)`
  height: 64px;
  min-height: 64px;
  width: 64px;
  min-width: 64px;
`;

const AddTagsMessage = styled.p`
  color: ${slate3};
  ${title24}
  display: flex;
  flex-direction: row-wrap;
  justify-content: center;
  margin: ${spaces.medium}px ${spaces.large}px 0;
  max-width: 400px;
`;

const ExploreButton = styled(Button2Large)`
  ${label16Caps}
  margin-top: ${spaces.xxLarge}px;
  color: ${white};
  background-color: ${slate1};
  box-shadow: none;
  height: 48px;
  min-height: 48px;
  width: 280px;
  padding: 0 30px 0 30px;
`;

const SearchOrCreateButton = styled(Button1Large)`
  ${label16Caps}
  margin-top: ${spaces.medium}px;
  color: ${slate1};
  background-color: ${gray1};
  border: 1px solid ${slate1};
  box-shadow: none;
  height: 48px;
  min-height: 48px;
  width: 280px;
  padding: 0 30px 0 30px;
`;
