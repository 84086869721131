import React from 'react';
import styled, { css } from 'styled-components';
import { media } from '@peloton/styles';
import { white, slate3, paleBlue } from '@engage/colors';
import { spaces } from '@engage/styles';
import { title24Italic } from '@engage/typography';
import { HashTag as HashTagIcon } from './icons/HashTag';

export const HashTag: React.FC<
  React.PropsWithChildren<{
    isCreatingTag?: boolean;
    tagName: string;
    isCentered?: boolean;
    isResponsive?: boolean;
  }>
> = ({ isCreatingTag = false, tagName, isCentered = false, isResponsive = true }) => {
  const TagTextComponent = isCentered ? CenteredTagText : TagText;

  return (
    <TagNameContainer isCentered={isCentered} isResponsive={isResponsive}>
      <HashContainer isResponsive={isResponsive}>
        <StyledHashTagIcon
          topHashColor={paleBlue}
          bottomHashColor={paleBlue}
          $isResponsive={isResponsive}
        />
      </HashContainer>
      <TagTextComponent
        isCreatingTag={isCreatingTag}
        data-test-id="tagName"
        isResponsive={isResponsive}
      >
        {tagName}
      </TagTextComponent>
    </TagNameContainer>
  );
};

const TagNameContainer = styled.div<{ isCentered: boolean; isResponsive: boolean }>`
  display: flex;
  align-items: center;
  ${title24Italic}
  width: 100%;
  max-width: 100%;

  ${props =>
    props.isCentered
      ? css`
          justify-content: center;
          padding: ${spaces.xSmall}px;
        `
      : null}
`;

const HashContainer = styled.span<{ isResponsive: boolean }>`
  display: flex;
  margin-right: ${spaces.xxxSmall}px;

  width: 24px;
  height: 24px;

  ${({ isResponsive }) =>
    isResponsive
      ? media.tablet`
          width: 28px;
          height: 28px;
        `
      : ''}
`;

const StyledHashTagIcon = styled(HashTagIcon)<{ $isResponsive: boolean }>`
  width: 100%;
  height: 100%;

  ${({ $isResponsive }) =>
    $isResponsive
      ? media.tablet`
          width: 28px;
          height: 28px;
        `
      : ''}
`;

const BaseTagText = styled.p<{ isCreatingTag: boolean; isResponsive: boolean }>`
  display: inline-block;
  overflow: visible;
  color: ${p => (p.isCreatingTag ? slate3 : white)};
  ${title24Italic}
`;

const TagText = styled(BaseTagText)`
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const CenteredTagText = styled(BaseTagText)`
  word-break: break-all;
  text-align: center;
  ${title24Italic}
`;
