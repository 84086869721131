import type { Class } from '../models/Class';
import type { ClassSelectorState } from './redux';

export enum ClassActionTypes {
  REQUEST = 'pelo/class/fetch/REQUEST',
  REQUEST_SUCCESS = 'pelo/class/fetch/REQUEST_SUCCESS',
  REQUEST_FAILURE = 'pelo/class/fetch/REQUEST_FAILURE',
}

export const getClass = (state: ClassSelectorState, id: string) =>
  state.classes[id]?.entity;

export const loadClassSuccess = (klass: Class) => ({
  type: ClassActionTypes.REQUEST_SUCCESS,
  payload: klass,
});

export const loadClassFailure = (id: string, error: Error) => ({
  type: ClassActionTypes.REQUEST_FAILURE,
  meta: { id },
  payload: error,
});
