/* eslint-disable react/display-name */
import React from 'react';
import styled from 'styled-components';
import { hover } from '@peloton/styles';
import { slate1, white } from '@engage/colors';
import { spaces } from '@engage/styles';
import { label14 } from '@engage/typography';
import { track } from '@members/analytics';
import { TopNav, TopNavCopy } from '@members/copy';
import {
  CircledPlay,
  Collections as CollectionsIcon,
  Programs as ProgramsIcon,
} from '@members/icons';
import { COLLECTIONS_ROUTE } from '@members/pg-collections';
import { trackViewedClassGroupLibrary } from '@members/pg-homescreen/analytics';
import { LIBRARY_CATEGORY_SELECTION_ROUTE } from '@members/pg-library/urls';
import { PROGRAMS_ROUTE } from '../../../pg-programs/urls';
import { MainLink, makeLinkActivatable } from '../../sharedLinkStyles';
import { ANALYTICS_SOURCE } from '../analytics';

type WorkoutsDropdownProps = {
  isMenuOpen: boolean;
};

export const WorkoutsDropdown = React.forwardRef<HTMLUListElement, WorkoutsDropdownProps>(
  (props, ref) => {
    return (
      <WorkoutsContainer
        id="workouts-menu"
        aria-hidden={!props.isMenuOpen}
        isMenuOpen={props.isMenuOpen}
        ref={ref}
      >
        <li>
          <ClassesLink />
        </li>
        <li>
          <ProgramsLink />
        </li>
        <li>
          <CollectionsLink />
        </li>
      </WorkoutsContainer>
    );
  },
);

const ClassesLink: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <WorkoutsDropdownLink
      className="dropdown-link"
      to={{
        pathname: LIBRARY_CATEGORY_SELECTION_ROUTE,
        state: { source: ANALYTICS_SOURCE },
      }}
      data-test-id="classesLink"
    >
      <CircledPlay />
      <TopNavCopy id={TopNav.Classes} />
    </WorkoutsDropdownLink>
  );
};

const CollectionsLink: React.FC<React.PropsWithChildren<unknown>> = () => {
  const trackAnalyticsLink = () => track(trackViewedClassGroupLibrary('Top Nav'));

  return (
    <WorkoutsDropdownLink
      className="dropdown-link"
      to={{
        pathname: COLLECTIONS_ROUTE,
        state: { source: ANALYTICS_SOURCE },
      }}
      data-test-id="collectionsLink"
      onClick={trackAnalyticsLink}
    >
      <CollectionsIcon />
      <TopNavCopy id={TopNav.Collections} />
    </WorkoutsDropdownLink>
  );
};

const ProgramsLink: React.FC<React.PropsWithChildren<unknown>> = () => (
  <WorkoutsDropdownLink
    className="dropdown-link"
    to={{
      pathname: PROGRAMS_ROUTE,
      state: { source: ANALYTICS_SOURCE },
    }}
    data-test-id="programsLink"
  >
    <ProgramsIcon />
    <TopNavCopy id={TopNav.Programs} />
  </WorkoutsDropdownLink>
);

const StyledLink = styled(MainLink)`
  ${label14}
  margin-bottom: 0px;
  color: ${slate1};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${spaces.small}px;
  gap: ${spaces.small}px;
  justify-content: flex-start;
  border-radius: 0px;
  fill: ${slate1};
  ${hover`
    border-radius: 4px;
  `}
`;

const WorkoutsDropdownLink = makeLinkActivatable(StyledLink);

const WorkoutsContainer = styled.ul<{ isMenuOpen: boolean }>`
  border-radius: 4px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  position: absolute;
  top: 48px;
  width: 166px;
  background: ${white};
  ${p => !p.isMenuOpen && `display: none;`}
`;
