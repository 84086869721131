import { compose, prop, values, isNil } from 'ramda';
import type { ScheduledClass } from '../models/ScheduledClass';
import type { ScheduleSelectorState } from '../redux';
import { SCHEDULE_NAMESPACE } from '../redux';

export const getReservationIdForClass = (
  state: ScheduleSelectorState,
  scheduledClassId: string,
) =>
  compose<ScheduleSelectorState, ScheduledClass | {}, string | undefined>(
    prop('reservationId'),
    scheduleState => getScheduledClass(scheduleState, scheduledClassId) ?? {},
  )(state);

export const isCountedInToScheduledClass = (
  state: ScheduleSelectorState,
  scheduledClassId: string,
) => Boolean(getReservationIdForClass(state, scheduledClassId));

export const getScheduledClasses = (
  state: ScheduleSelectorState,
): ScheduledClass[] | undefined => values(state[SCHEDULE_NAMESPACE].classes);

export const getScheduledClass = (
  state: ScheduleSelectorState,
  liveId: string,
): ScheduledClass | undefined => state[SCHEDULE_NAMESPACE]?.classes[liveId];

export const isScheduleLoading = (state: ScheduleSelectorState) =>
  state[SCHEDULE_NAMESPACE].isLoading || isNil(state[SCHEDULE_NAMESPACE].isLoading);
