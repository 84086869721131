import { pluck } from 'ramda';
import type { Reducer } from 'redux';
import type { TypedAction } from '@peloton/redux';
import { isDefined } from '@peloton/types';
import type {
  RejectFollowerSuccessAction,
  RemoveFollowerSuccessAction,
} from '@engage/me';
import { RelationshipsActionType } from '@engage/me';
import type { Member } from '@engage/members';
import type { PageNumber } from '@engage/pagination';
import type { PendingResult } from '@engage/result';
import { pending, ok, isOk, error as engageError } from '@engage/result';
import { FriendType } from '../models/FriendType';

const toNamespace = (state: State, id: string, friendType: FriendType) => {
  const namespace = friendsNamespace(id, friendType);
  const friends = state[namespace];
  return { namespace, friends };
};

export const friendsReducer: Reducer<State> = (state = {}, action: FriendsAction) => {
  switch (action.type) {
    case FriendsActionType.RequestFriends: {
      const { namespace: requestedNamespace, friends: friendsRequested } = toNamespace(
        state,
        action.payload.id,
        action.payload.friendType,
      );

      if (!isDefined(friendsRequested)) {
        return {
          ...state,
          [requestedNamespace]: pending,
        };
      }
      return state;
    }
    case FriendsActionType.LoadingFriends: {
      const { namespace: loadingNamespace, friends: friendsLoading } = toNamespace(
        state,
        action.payload.id,
        action.payload.friendType,
      );

      if (isOk(friendsLoading)) {
        return {
          ...state,
          [loadingNamespace]: ok({
            ...friendsLoading.value,
            nextPage: pending,
          }),
        };
      }
      return state;
    }
    case FriendsActionType.AddFriends: {
      const { namespace, friends } = toNamespace(
        state,
        action.meta.id,
        action.meta.friendType,
      );

      if (friends === pending) {
        return {
          ...state,
          [namespace]: ok({
            ids: action.payload.ids,
            nextPage: ok(action.payload.nextPage),
          }),
        };
      } else if (isDefined(friends) && isOk(friends)) {
        return {
          ...state,
          [namespace]: ok({
            ids: [...new Set(friends.value.ids.concat(action.payload.ids))],
            nextPage: ok(action.payload.nextPage),
          }),
        };
      }
      return state;
    }
    case FriendsActionType.AddFriendsFailure: {
      const { namespace: failedNamespace, friends: failedFriends } = toNamespace(
        state,
        action.meta.id,
        action.meta.friendType,
      );

      if (failedFriends === pending) {
        return {
          ...state,
          [failedNamespace]: engageError(undefined),
        };
      } else if (isOk(failedFriends)) {
        return {
          ...state,
          [failedNamespace]: ok({
            ...failedFriends.value,
            nextPage: engageError({ page: action.pageNumber }),
          }),
        };
      }
      return state;
    }
    case RelationshipsActionType.RemoveSuccess:
    case RelationshipsActionType.RejectSuccess: {
      const myFollowersNamespace = friendsNamespace(
        action.payload.userId,
        FriendType.Follower,
      );
      const myFollowers = state[myFollowersNamespace];

      if (isDefined(myFollowers) && isOk(myFollowers)) {
        const ids = myFollowers.value.ids;
        const newIds = ids.filter(id => id !== action.payload.followerId);
        return {
          ...state,
          [myFollowersNamespace]: ok({
            ...myFollowers.value,
            ids: newIds,
          }),
        };
      }
      return state;
    }

    default:
      return state;
  }
};

export enum FriendsActionType {
  RequestFriends = 'pelo/friends/REQUEST_NEXT_PAGE',
  LoadingFriends = 'pelo/friends/LOADING_NEXT_PAGE',
  AddFriends = 'pelo/friends/ADD',
  AddFriendsFailure = 'pelo/friends/ADD_FAILURE',
}

export type FriendsSelectorState = { friends: State };
type State = Record<string, FriendsState>;
type FriendsState = PendingResult<
  {
    ids: string[];
    nextPage: PendingResult<PageNumber, { page: number }>;
  },
  undefined
>;

type FriendsAction =
  | RemoveFollowerSuccessAction
  | RejectFollowerSuccessAction
  | TypedAction<ReturnType<typeof addFriends>, FriendsActionType.AddFriends>
  | TypedAction<ReturnType<typeof addFriendsFailure>, FriendsActionType.AddFriendsFailure>
  | TypedAction<ReturnType<typeof requestFriends>, FriendsActionType.RequestFriends>
  | TypedAction<ReturnType<typeof loadingFriends>, FriendsActionType.LoadingFriends>;

export type RequestFriendsAction = TypedAction<
  ReturnType<typeof requestFriends>,
  FriendsActionType.RequestFriends
>;

export const addFriendsFailure = (
  id: string,
  friendType: FriendType,
  pageNumber: number,
) => ({
  type: FriendsActionType.AddFriendsFailure,
  meta: { id, friendType },
  pageNumber,
});

export const requestFriends = (
  id: string,
  friendType: FriendType,
  initialLoad?: boolean,
) => ({
  type: FriendsActionType.RequestFriends,
  payload: { id, friendType, initialLoad },
});

export const loadingFriends = (id: string, friendType: FriendType) => ({
  type: FriendsActionType.LoadingFriends,
  payload: { id, friendType },
});

export const addFriends = (
  id: string,
  friendType: FriendType,
  friends: Member[],
  nextPage: PageNumber,
) => ({
  type: FriendsActionType.AddFriends,
  meta: { id, friendType },
  payload: { ids: pluck('id')(friends), nextPage },
});

export const friendsNamespace = (id: string, friendType: FriendType) =>
  `friends/${id}/${friendType}`;
