import React from 'react';
import { toNowTime, getDifferenceIsLessThan } from '@peloton/time';
import { track } from '@engage/analytics';
import { useReduxAction } from '@engage/redux';
import { useFeatureVariables, Feature } from '@members/feature-toggles';
import LoadingPage from '@members/loading/LoadingPage';
import { viewedTieringModal } from './analytics';
import { useMembershipTieringInfo } from './hooks/useMembershipTieringInfo';
import type { TierContextType, Modal, ModalClassHandler } from './models';
import {
  DEFAULT_TIER_CONTEXT,
  DEFAULT_MODAL,
  DEFAULT_MODAL_CLASS_HANDLER,
} from './models';
import { getModalName, getModalTitle } from './utils';

const TierContext = React.createContext<TierContextType>(DEFAULT_TIER_CONTEXT);

export const useTierContext = () => React.useContext(TierContext);

export const TierProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [modal, setModal] = React.useState<Modal>(DEFAULT_MODAL);
  const [modalClassHandler, setModalClassHandler] = React.useState<ModalClassHandler>(
    DEFAULT_MODAL_CLASS_HANDLER,
  );

  const [lastFetchTime, setLastFetchTime] = React.useState(toNowTime());

  const trackAnalytics = useReduxAction(track);

  const setModalWithAnalytics = (updatedModal: Modal) => {
    if (updatedModal.isOpen) {
      let namedSource = updatedModal.source ?? '';
      if (updatedModal.source === undefined || updatedModal.source === '') {
        namedSource = 'Source Not Found';
      }
      const modalName = getModalName(updatedModal.modalType);
      const modalTitle = getModalTitle(updatedModal.modalType);
      trackAnalytics(viewedTieringModal(modalName, modalTitle, namedSource));
    }
    setModal(updatedModal);
  };

  const {
    isLoading: isDataLoading,
    isEligibleForFreeTrial,
    computedTierData,
    marketingData,
    maybeRawTieringData,
    mutateTieringInfo,
  } = useMembershipTieringInfo();

  // override tier type if radio testing is enabled
  const [_, radioFeatureVariables] = useFeatureVariables(Feature.StaticStretching);
  if (radioFeatureVariables?.isRadioUser) {
    computedTierData.tierType = 'RADIO';
  }

  const refetch = () => {
    const isMoreThanFiveSecondsStale = !getDifferenceIsLessThan(
      toNowTime(),
      lastFetchTime,
      5,
      'seconds',
    );
    if (isMoreThanFiveSecondsStale) {
      mutateTieringInfo();
      setLastFetchTime(toNowTime());
    }
  };

  return isDataLoading ? (
    <LoadingPage styledBackground={false} />
  ) : (
    <TierContext.Provider
      value={{
        tier: computedTierData,
        modal: modal,
        setModal: setModalWithAnalytics,
        modalClassHandler: modalClassHandler,
        setModalClassHandler: setModalClassHandler,
        marketingData,
        rawTieringData: maybeRawTieringData,
        isEligibleForFreeTrial: isEligibleForFreeTrial,
        refetch,
      }}
    >
      {children}
    </TierContext.Provider>
  );
};
