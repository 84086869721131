import React from 'react';
import styled from 'styled-components';
import useSWR from 'swr';
import { isNil } from '@peloton/helpers/isNil';
import { toLanguageFromLocale, Language } from '@peloton/internationalize';
import { ieHideScroll } from '@peloton/styles';
import { gray1 } from '@engage/colors';
import { useMemberId } from '@engage/overview';
import { spaces } from '@engage/styles';
import { Tags, useTags, useCta, Cta } from '@members/copy';
import { TagsFetchersGQL } from '@members/data-fetch';
import { useLocale } from '@members/localization';
import { useTrackViewedTagList } from '../analytics';
import { ErrorView } from '../ErrorView';
import { Loading } from '../Loading';
import { PERSISTENT_NAV_BAR_HEIGHT } from '../MainView/ScrollContext';
import { SharedHeader as Header } from '../Modal/TagsModalHeaderView';
import { toClientTag } from '../models';
import { Tag } from '../Tag/Tag';
import { TagsContext } from '../TagsContext';
import { AddTags } from './AddTags';
import { NoTagsView } from './NoTagsView';

const getContainerPadding = (lang: Language, isMyTags: boolean) => {
  if (!isMyTags) {
    return 0;
  }
  if (lang === Language.German) {
    return PERSISTENT_NAV_BAR_HEIGHT + 14;
  } else {
    return PERSISTENT_NAV_BAR_HEIGHT;
  }
};

export const OtherMembersTagsView: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { goBack } = React.useContext(TagsContext);
  const userId = useMemberId();
  const { data, error, mutate: refetch } = useSWR(
    ...TagsFetchersGQL.UserTagsQuery({ userId }),
  );
  const loading = !data;

  const tagsList =
    data?.user?.userTags?.allTags?.map(userTag => ({
      tag: toClientTag(userTag.tag),
      isPrimary: userTag.isPrimary,
    })) ?? [];

  const fetched = !loading;

  const locale = useLocale();
  const lang = toLanguageFromLocale(locale);

  const paddingContainer = React.useMemo(() => getContainerPadding(lang, false), [lang]);

  useTrackViewedTagList(fetched, false, userId);

  return (
    <Container paddingContainer={paddingContainer}>
      <Header
        button={{
          props: {
            onClick: goBack,
          },
          content: useCta(Cta.Back),
        }}
        headerText={{ id: Tags.Tags, renderer: useTags }}
        username={data?.user?.username}
      />
      {loading ? (
        <Loading isLoading={loading} size={40} />
      ) : !isNil(error) || !data?.user ? (
        <ErrorView tryAgain={() => refetch()} />
      ) : (
        <Body>
          <TagsList>
            {tagsList.map(({ tag, isPrimary }) => (
              <Tag
                key={tag.name}
                viewingOwnTag={false}
                isPrimary={isPrimary}
                tagName={tag.name}
                metaTag={tag.metaTag}
                numberOfMembers={tag.numberOfMembers}
                numberOfFollowing={tag.numberOfFollowing}
                backgroundImageUrl={tag.backgroundImageUrl}
                hasAdded={tag.hasAdded}
                isCreatingTag={false}
              />
            ))}
          </TagsList>
        </Body>
      )}
    </Container>
  );
};

export const MyTagsView: React.FC<React.PropsWithChildren<unknown>> = () => {
  const userId = useMemberId();
  const { data, error, mutate: refetch } = useSWR(...TagsFetchersGQL.MyTagsQuery({}));
  const loading = !data;

  const remainingTagCount = data?.user?.remainingTagSlotCount ?? 0;
  const [primaryTagIndex, setPrimaryTagIndex] = React.useState(
    data?.user?.userTags?.allTags?.findIndex(t => t?.isPrimary) ?? 0,
  );
  const tagsList =
    data?.user?.userTags?.allTags.map((userTag, idx) => ({
      tag: toClientTag(userTag.tag),
      isPrimary: idx === primaryTagIndex,
    })) ?? [];

  const fetched = !loading;
  useTrackViewedTagList(fetched, true, userId);

  const locale = useLocale();
  const lang = toLanguageFromLocale(locale);
  const paddingContainer = React.useMemo(() => getContainerPadding(lang, true), [lang]);
  return (
    <Container paddingContainer={paddingContainer}>
      {loading ? (
        <Loading isLoading={loading} size={40} />
      ) : !isNil(error) || !data?.user ? (
        <ErrorView tryAgain={() => refetch()} />
      ) : tagsList?.length === 0 ? (
        <NoTagsView />
      ) : (
        <Body>
          <AddTags remainingTagCount={remainingTagCount} />
          <TagsList>
            {tagsList.map(({ tag, isPrimary }, i) => (
              <Tag
                currentIndex={i}
                setPrimaryTagIndex={setPrimaryTagIndex}
                key={tag.name}
                viewingOwnTag={true}
                isPrimary={isPrimary}
                tagName={tag.name}
                metaTag={tag.metaTag}
                numberOfMembers={tag.numberOfMembers}
                numberOfFollowing={tag.numberOfFollowing}
                backgroundImageUrl={tag.backgroundImageUrl}
                hasAdded={tag.hasAdded}
                isCreatingTag={false}
                isOnlyTag={tagsList.length === 1}
              />
            ))}
          </TagsList>
        </Body>
      )}
    </Container>
  );
};

const Container = styled.div<{ paddingContainer: number }>`
  padding-top: ${({ paddingContainer }) => `${paddingContainer}px`};
  height: 100%;
  width: 100%;
  background-color: ${gray1};
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  /* hide scrollbar*/
  ::-webkit-scrollbar {
    height: 0;
    width: 0;
    background: transparent;
  }
  ${ieHideScroll}
`;

const Body = styled.div`
  padding: ${spaces.small}px;
`;
Body.displayName = 'Body';

const TagsList = styled.ul`
  > li:not(:first-child) {
    margin-top: ${spaces.small}px;
  }
`;
TagsList.displayName = 'TagsList';
