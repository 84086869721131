import type { SagaIterator } from 'redux-saga';
import { call, getContext, takeEvery, select, put } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT } from '@peloton/api';
import { getSignedInUserId } from '@peloton/auth';
import { uploadToS3, updateUserImage, getImageUploadUrl, getDefaultImage } from './api';
import type { UploadImageUrls } from './models/UploadImageUrls';
import type { RequestAction } from './redux';
import {
  AvatarUploaderActions,
  receiveDefaultAvatar,
  updateAvatarSuccess,
} from './redux';

export const getImageUploadUrlSaga = function* (
  client: Client,
  action: RequestAction,
): SagaIterator {
  const userId = yield select(getSignedInUserId);
  const file = action.payload.file;
  const uploadUrls = yield call(getImageUploadUrl, client, userId);

  if (file && file.type) {
    yield call(uploadSaga, client, file, uploadUrls, userId);
  } else {
    yield call(updateWithDefaultImageSaga, client, file, uploadUrls, userId);
  }
};

export const uploadSaga = function* (
  client: Client,
  file: File,
  uploadUrls: UploadImageUrls,
  userId: string,
) {
  yield call(uploadToS3, file, uploadUrls.uploadUrl);
  yield call(updateUserSaga, client, userId, uploadUrls.newImageUrl);
};

export const updateUserSaga = function* (
  client: Client,
  userId: string,
  imageUrl: string,
): SagaIterator {
  const updatedImageUrl = yield call(updateUserImage, client, userId, imageUrl);
  yield put(updateAvatarSuccess(updatedImageUrl));
};

export const updateWithDefaultImageSaga = function* (
  client: Client,
  file: File,
  uploadUrls: UploadImageUrls,
  userId: string,
): SagaIterator {
  const defaultImageUrl = yield call(getDefaultImage, client);

  yield call(uploadToS3, file, uploadUrls.uploadUrl);
  yield call(updateUserSaga, client, userId, uploadUrls.newImageUrl);
  yield put(updateAvatarSuccess(defaultImageUrl));
};

export const getDefaultImageSaga = function* (client: Client): SagaIterator {
  const defaultImageUrl = yield call(getDefaultImage, client);

  yield put(receiveDefaultAvatar(defaultImageUrl));
};

export const avatarUpdaterSaga = function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  yield takeEvery(AvatarUploaderActions.Request, getImageUploadUrlSaga, client);
  yield takeEvery(AvatarUploaderActions.RequestDefault, getDefaultImageSaga, client);
};
