import type { RedirectLoginResult, LogoutOptions } from '@auth0/auth0-spa-js';
import type { AxiosInstance } from 'axios';
import React from 'react';

import { toClientDetailsHeader } from '@peloton/analytics/clientDetailsHeader';
import type { LogoutOptions as PLogoutOptions } from '@peloton/auth';
import { deAuthenticate } from '@peloton/auth';
import { addAuthParamsToV2ConfigSchema } from '@peloton/auth/oauthConfigSchema';
import POauthProvider, { useOauth } from '@peloton/auth/OauthProvider';
import type { ApiEnvs } from '@peloton/external-links';
import {
  safelyDecode,
  getExtLinkEnv,
  toRedirectUrl,
  REDIRECT_URL_QUERY_PARAM,
} from '@peloton/external-links';
import { history } from '@peloton/redux';

import { logoutPost } from '@engage/api-v2';
import { useReduxState, useReduxAction } from '@engage/redux';

import {
  engageApi,
  engageApiTextResponseType,
  presenceApi,
  pagesApi,
  graphQlApi,
  tooltipsApi,
  guidanceApi,
  progressTrackingApi,
  contentPreferencesApi,
  userPreferencesApi,
} from '@members/data-fetch';
import useAuthorizationHeader from '@members/data-fetch/useAuthorizationHeader';
import { toAuth0Config, apiToMaybeOauthEnv, toAuthEnv } from '@members/env';

const maybePathname = typeof window === 'undefined' ? '' : window.location.pathname;
const maybeOrigin = typeof window === 'undefined' ? '' : window.origin;

const WaitForInitializationWithHeaders: React.FC<
  React.PropsWithChildren<{ reduxClient: AxiosInstance }>
> = ({ reduxClient, children }) => {
  const { isLoading } = useOauth();

  useAuthorizationHeader([
    engageApi.axios,
    engageApiTextResponseType.axios,
    presenceApi.axios,
    pagesApi.axios,
    graphQlApi.axios,
    tooltipsApi.axios,
    guidanceApi.axios,
    progressTrackingApi.axios,
    contentPreferencesApi.axios,
    userPreferencesApi.axios,
    reduxClient,
  ]);
  // Wait for Oauth to be initialized before rendering providers/components that will request data
  return <>{isLoading ? null : children}</>;
};

const OauthProvider: React.FC<
  React.PropsWithChildren<{
    apiEnv: ApiEnvs;
    reduxClient: AxiosInstance;
  }>
> = props => {
  const oauthEnv = apiToMaybeOauthEnv(props.apiEnv);
  const extLinkEnv = useReduxState(getExtLinkEnv);

  const onRedirectCallback = async (
    appStateOnRedirect: RedirectLoginResult['appState'],
  ) => {
    const path = safelyDecode(appStateOnRedirect[REDIRECT_URL_QUERY_PARAM]);
    const redirectUrl = toRedirectUrl({ extLinkEnv, path });

    history.replace(`${maybePathname}/?redirectUrl=${btoa(redirectUrl)}`);
  };

  const auth0Config = toAuth0Config(props.apiEnv, oauthEnv);
  const authorizationParams = {
    redirect_uri: `${maybeOrigin}/callback`,
    scope: 'openid offline_access',
  };
  const authEnv = toAuthEnv();

  return (
    <POauthProvider
      {...addAuthParamsToV2ConfigSchema(auth0Config, authorizationParams)}
      onRedirectCallback={onRedirectCallback}
      authEnv={authEnv}
    >
      <WaitForInitializationWithHeaders reduxClient={props.reduxClient}>
        {props.children}
      </WaitForInitializationWithHeaders>
    </POauthProvider>
  );
};

export default OauthProvider;

export const useLogout = () => {
  const { logout } = useOauth();
  const deauth = useReduxAction(deAuthenticate);

  return async (
    options: PLogoutOptions = {},
    oauthLogoutOptions: LogoutOptions = {},
    shouldLogoutLegacy: boolean = true,
  ) => {
    if (shouldLogoutLegacy) {
      try {
        await logoutPost(engageApi, toClientDetailsHeader(options));
        deauth();
      } catch (e) {
        // if we fail to logout of legacy, continue to oauth logout anyways
      }
    }

    const { logoutParams, ...logoutOptions } = oauthLogoutOptions;

    await logout(
      {
        ...(!!logoutOptions ? logoutOptions : {}),
        logoutParams: {
          returnTo: window.location.href,
          ...(!!logoutParams ? logoutParams : {}),
        },
      },
      true,
    );
  };
};
