import type { FetcherSelectorState } from '@peloton/redux-fetch';
import { isDefined } from '@peloton/types';
import { pending, isOk, isError } from '@engage/result';
import type { ClassDetail } from './models';
import type { classDetailReducer } from './redux';

export const getNormalizedClassDetail = (
  state: ClassDetailSelectorState,
): ClassDetail | undefined => {
  const detail = state.classDetail.detail;
  return isOk(detail) ? detail.value : undefined;
};

export const getIsClassLoading = (state: ClassDetailSelectorState) =>
  state.classDetail.detail === pending;

export const getIsFriendsLoading = (state: ClassDetailSelectorState) =>
  state.classDetail.friends === pending;

export const isClassDetailError = (state: ClassDetailSelectorState) =>
  isError(state.classDetail.detail);

export const getClassDetail = (
  state: ClassDetailSelectorState,
): ClassDetail | undefined => {
  const detail = getNormalizedClassDetail(state);

  return isDefined(detail)
    ? {
        playlist: detail.playlist,
        radioData: detail.radioData,
        segments: detail.segments,
        ride: detail.ride,
        sampledTopTags: detail.sampledTopTags,
        averages: detail.averages,
      }
    : undefined;
};

export const getFriendsWhoTookClass = (state: ClassDetailSelectorState) =>
  isOk(state.classDetail.friends) ? state.classDetail.friends.value ?? [] : [];

type ReduxState = { classDetail: ReturnType<typeof classDetailReducer> };
type SelectorState = FetcherSelectorState & ReduxState;
export type ClassDetailSelectorState = SelectorState;
