import React from 'react';
import styled, { keyframes } from 'styled-components';
import { hoverTransition } from '@peloton/styles';
import { gray4, white, slate1 } from '@engage/colors';
import { spaces } from '@engage/styles';
import CancelIcon from '../icons/CancelIcon';

const DarkClearButton: React.FC<React.PropsWithChildren<Props>> = props => (
  <Button data-test-id="clearButton" type="button" dark {...props}>
    <DarkIcon />
  </Button>
);

const LightClearButton: React.FC<React.PropsWithChildren<Props>> = props => (
  <Button data-test-id="clearButton" type="button" {...props}>
    <LightIcon />
  </Button>
);

const DarkIcon = styled(CancelIcon)`
  cursor: pointer;
  width: 16px;
  height: 16px;
  ${hoverTransition({
    property: 'fill',
    from: gray4,
    to: white,
  })}
`;

const LightIcon = styled(CancelIcon)`
  cursor: pointer;
  width: 16px;
  height: 16px;
  ${hoverTransition({
    property: 'fill',
    from: gray4,
    to: slate1,
  })}
`;

const FadeIn = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
`;

const Button = styled.button<{ dark?: boolean }>`
  text-align: center;
  font-size: 11px;
  height: 30px;
  width: 30px;
  position: absolute;
  bottom: ${({ dark }) => (dark ? '13px' : '9px')};
  right: ${spaces.xxSmall}px;
  animation: ${FadeIn} 1s ease;
`;

type Props = {
  onClick(): void;
};

export { DarkClearButton, LightClearButton };
