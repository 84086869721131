// eslint-disable-next-line no-restricted-imports
import { prop } from 'ramda';
import { mapRideToClass } from '@engage/classes';
import type { Member } from '@engage/members/models/Member';
import { RelationshipCategory, MemberListGroup } from '@engage/members/models/Member';
import type { ApiClassDetail, ApiSegment, ApiFriendsResponse } from './api';
import type { ClassDetail, Segment } from './models';
import { MetricsType } from './models';

const toMetricsType = (rawMetricsType: string): MetricsType => {
  switch (rawMetricsType) {
    case MetricsType.Running:
      return MetricsType.Running;
    case MetricsType.Floor:
      return MetricsType.Floor;
    case MetricsType.Cycling:
    default:
      return MetricsType.Running;
  }
};

export const toSegment = (apiSegment: ApiSegment): Segment => ({
  ...apiSegment,
  metricsType: toMetricsType(apiSegment.metricsType),
});

const toDetail = (apiDetail: ApiClassDetail): ClassDetail => ({
  playlist: apiDetail.playlist,
  radioData: apiDetail.radioData,
  segments: apiDetail.segments?.segmentList?.map(toSegment),
  ride: apiDetail.ride,
  averages: apiDetail.averages,
  sampledTopTags: apiDetail.sampledTopTags,
});

export const toClassDetail = (apiDetail: ApiClassDetail) => ({
  class: mapRideToClass(apiDetail.ride),
  detail: toDetail(apiDetail),
});

export const toFriendsList = (apiDetail: ApiFriendsResponse): Member[] =>
  apiDetail.data.map(apiMember => ({
    ...prop('user', apiMember),
    relationship: { meToUser: RelationshipCategory.Following },
    listGroup: MemberListGroup.Following,
  }));
