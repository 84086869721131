import React from 'react';
import styled, { css } from 'styled-components';
import { theUserIsInAustralia } from '@peloton/internationalize';
import { media } from '@peloton/styles';
import { spaces } from '@engage/styles';
import { header6Large, label12 } from '@engage/typography';
import { AppDownload, AppDownloadCopy, useAppDownload } from '@members/copy';
import { useAnalytics, App } from '@members/get-apps';
import { Image } from '@members/images';
import { useLocale } from '@members/localization';
import { AnalyticsSource } from './analytics';
import AndroidBadge from './icons/AndroidBadge';
import AndroidTVBadge from './icons/AndroidTVBadge';
import AppleBadge from './icons/AppleBadge';
import AppleTVBadge from './icons/AppleTVBadge';
import FireBadge from './icons/FireBadge';
import RokuBadge from './icons/RokuBadge';
import { mobileTablet, tv } from './img';
import { Title, Divider, cardStyles, backgroundStyles } from './shared';

type Props = {
  isOpen: boolean;
};

const GetAppsFromGenericDevice: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
}) => {
  const appsDisplayed = [App.IOS, App.Android, App.FireTV];
  const [onDownloadClick] = useAnalytics(appsDisplayed, AnalyticsSource.MoreMenu, isOpen);

  const androidLink = useAppDownload(AppDownload.AppStoreAndroid);
  const iOSLink = useAppDownload(AppDownload.AppStoreIOS);
  const appleTVLink = useAppDownload(AppDownload.AppStoreAppleTV);
  const amazonLink = useAppDownload(AppDownload.AppStoreAmazon);
  const rokuLink = useAppDownload(AppDownload.AppStoreRoku);

  const locale = useLocale();

  return (
    <Container>
      <Title>
        <AppDownloadCopy id={AppDownload.TitleGenericDevice} />
      </Title>
      <StyledDivider />
      <Cards>
        <Card>
          <CardImage
            src={
              typeof mobileTablet === 'string'
                ? mobileTablet
                : (mobileTablet as { src: string }).src
            }
            alt=""
            breakpointOptions={{ mobile: { width: 456 } }}
          />
          <CardHeader>
            <AppDownloadCopy id={AppDownload.MobileTablet} />
          </CardHeader>
          <MessageGeneric>
            <AppDownloadCopy id={AppDownload.MobileTabletMessage} />
          </MessageGeneric>
          <AppBadges>
            <AppBadge
              aria-label="iOS"
              href={iOSLink}
              onClick={() => onDownloadClick(App.IOS)}
              rel="noopener"
              target="_blank"
            >
              <Apple />
            </AppBadge>
            <AppBadge
              aria-label="Android"
              href={androidLink}
              onClick={() => onDownloadClick(App.Android)}
              rel="noopener"
              target="_blank"
            >
              <Android />
            </AppBadge>
          </AppBadges>
        </Card>
        <Card>
          <CardImage
            src={typeof tv === 'string' ? tv : (tv as any).src}
            alt=""
            breakpointOptions={{ mobile: { width: 456 } }}
          />
          <CardHeader>
            <AppDownloadCopy id={AppDownload.Tv} />
          </CardHeader>
          <MessageGeneric>
            <AppDownloadCopy id={AppDownload.TvMessageGeneric} />
          </MessageGeneric>
          <AppBadges>
            <AppBadge
              aria-label="Apple TV"
              href={appleTVLink}
              onClick={() => onDownloadClick(App.AppleTV)}
              rel="noopener"
              target="_blank"
            >
              <AppleTV />
            </AppBadge>
            <AppBadge
              aria-label="Android TV"
              href={androidLink}
              onClick={() => onDownloadClick(App.AndroidTV)}
              rel="noopener"
              target="_blank"
            >
              <AndroidTV />
            </AppBadge>
            <AppBadge
              aria-label="Fire TV"
              href={amazonLink}
              onClick={() => onDownloadClick(App.FireTV)}
              rel="noopener"
              target="_blank"
            >
              <Fire />
            </AppBadge>
            {theUserIsInAustralia(locale) ? null : (
              <AppBadge
                aria-label="Roku"
                href={rokuLink}
                onClick={() => onDownloadClick(App.Roku)}
                rel="noopener"
                target="_blank"
              >
                <Roku />
              </AppBadge>
            )}
          </AppBadges>
        </Card>
      </Cards>
    </Container>
  );
};

const StyledDivider = styled(Divider)`
  margin-bottom: ${spaces.large}px;
  ${media.desktopLarge`
    margin-bottom: ${spaces.xxLarge}px;
  `}
`;

const Cards = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 456px;
  ${media.tablet`
    width: 100%;
  `}
  ${media.desktopLarge`
    flex-direction: row;
    width: auto;
    max-width: unset;
  `};
`;

const CardHeader = styled.h2`
  ${header6Large}
  font-weight: 400;
  margin-top: ${spaces.xLarge}px;
  ${media.desktop`
    margin-top: ${spaces.large}px;
  `}
`;

const MessageGeneric = styled.p`
  ${label12}
  text-align: center;
  margin: ${spaces.xxSmall}px ${spaces.medium}px 0;
`;

const Container = styled.div`
  ${backgroundStyles}
  padding-bottom: ${spaces.xxxLarge}px;
  ${media.desktopLarge`
    padding-bottom: ${spaces.xxLarge}px;
  `}
`;

const Card = styled.div`
  ${cardStyles}
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0;
  & + & {
    margin-top: ${spaces.large}px;
  }
  ${media.tablet`
    margin: 0 auto;
  `}
  ${media.desktopLarge`
    width: 450px;
    & + & {
      margin: 0 0 0 ${spaces.large}px;
    }
  `}
`;

const AppBadges = styled.div`
  padding: ${spaces.large}px 0 ${spaces.xLarge}px 0;
  display: grid;
  grid-auto-flow: unset;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: ${spaces.small}px;
  grid-row-gap: ${spaces.small}px;
  justify-items: center;

  ${media.tablet`
    grid-auto-flow: column;
    grid-template-columns: unset;
    grid-column-gap: ${spaces.medium}px;
    grid-row-gap: ${spaces.medium}px;
  `}
`;

const AppBadge = styled.a``;

const badgeStyles = css`
  height: 32px;
  ${media.tablet`
    height: 40px;
  `}
`;

const Apple = styled(AppleBadge)`
  height: 33px;
  ${media.tablet`
    height: 41px;
  `}
`;

const Android = styled(AndroidBadge)`
  ${badgeStyles}
`;

const AppleTV = styled(AppleTVBadge)`
  ${badgeStyles}
`;

const AndroidTV = styled(AndroidTVBadge)`
  ${badgeStyles}
`;

const Fire = styled(FireBadge)`
  ${badgeStyles}
`;

const Roku = styled(RokuBadge)`
  ${badgeStyles}
`;

const imageStyles = css`
  border-radius: 5px 5px 0 0;
  height: unset;
  width: 100%;
`;

const CardImage = styled(Image)`
  display: none;
  ${media.desktopLarge`
    display: block;
    ${imageStyles}
  `}
`;

export default GetAppsFromGenericDevice;
