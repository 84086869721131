import { toTimeFromDate, toIsoString } from '@peloton/time';
import type { ActiveDayHistory } from '@engage/overview-ui';
import type { ApiActivityCalendar } from '../models/ApiActivityCalendar';

export const toActiveDayHistory = (apiData: ApiActivityCalendar): ActiveDayHistory => {
  const res: string[] = [];
  apiData.months.forEach(month => {
    month.activeDays.forEach(day => {
      const date = new Date(month.year, month.month - 1, day);
      res.push(toIsoString(toTimeFromDate(date)));
    });
  });
  return res;
};
