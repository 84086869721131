import React from 'react';
import { branch, renderComponent } from 'recompose';
import styled, { css } from 'styled-components';
import type { Omit } from '@peloton/types';
import {
  white,
  backgroundInputGray,
  superRedA11y,
  gray5,
  gray3,
  gray4,
  slate1,
} from '@engage/colors';
import { spaces } from '@engage/styles';
import { LoadingSpinner } from '@members/loading';
import { Button1Large, Button1Medium, DarkButton1Large } from './buttons';

const ActionButton = styled(Button1Large)<ActionButtonProps>`
  display: block;
  margin: 0 auto;

  ${props =>
    !props.isValid
      ? `:disabled {
          color: ${gray4};
          background-color: ${backgroundInputGray};
          cursor: not-allowed;
          &:hover {
            filter: none;
          }
      }`
      : ''}
  ${props =>
    props.isSubmitting
      ? `:disabled {
          background-color: ${superRedA11y};
          color: ${white};
      }`
      : ''}
`;

const darkStyles = css<ActionButtonProps>`
  ${props =>
    !props.isValid
      ? `:disabled {
          color: ${gray5};
          background-color: ${gray3};
          cursor: not-allowed;
      }`
      : ''}
  ${props =>
    props.isSubmitting
      ? `:disabled {
          background-color: ${superRedA11y};
          color: ${white};
      }`
      : ''}
`;

const ActionButtonDark = styled(Button1Large)<ActionButtonProps>`
  ${darkStyles}
`;

const MediumButtonDark = styled(Button1Medium)<ActionButtonProps>`
  ${darkStyles}
`;

const SubmitText = ({ text }: { text: string }) => <span>{text}</span>;

const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin-top: ${spaces.xxxxSmall}px;
`;

const LoadingIconEnhancer = branch<Omit<SubmitButtonProps, 'StyledButton' | 'isValid'>>(
  props => props.isSubmitting,
  renderComponent((innerProps: { fill: string; loaderSize: number }) => (
    <StyledLoadingSpinner isLoading size={innerProps.loaderSize} fill={innerProps.fill} />
  )),
)(SubmitText);

const SubmitButton: React.FC<React.PropsWithChildren<SubmitButtonProps>> = ({
  isSubmitting,
  isValid = true,
  text,
  StyledButton = ActionButton,
  fill = white,
  loaderSize = 28,
  ...props
}) => (
  <StyledButton
    type="submit"
    disabled={isSubmitting || !isValid}
    isValid={isValid}
    isSubmitting={isSubmitting}
    data-test-id="loginButton"
    {...props}
  >
    <LoadingIconEnhancer
      fill={fill}
      loaderSize={loaderSize}
      isSubmitting={isSubmitting}
      text={text}
    />
  </StyledButton>
);

export const SubmitButtonDark: React.FC<
  React.PropsWithChildren<SubmitButtonProps>
> = props => <SubmitButton StyledButton={ActionButtonDark} {...props} />;

export const MediumSubmitButtonDark: React.FC<
  React.PropsWithChildren<SubmitButtonProps>
> = props => <SubmitButton StyledButton={MediumButtonDark} {...props} />;

export const LargeSubmitButtonDark: React.FC<
  React.PropsWithChildren<SubmitButtonProps>
> = props => <SubmitButton StyledButton={DarkButton1Large} {...props} fill={slate1} />;

export type ActionButtonProps = Partial<
  Pick<SubmitButtonProps, 'isSubmitting' | 'isValid'>
>;

export type SubmitButtonProps = {
  onClick?: (e: React.SyntheticEvent) => void;
  isSubmitting: boolean;
  text: string;
  isValid?: boolean;
  fill?: string;
  loaderSize?: number;
  StyledButton?: React.ComponentType<
    React.PropsWithChildren<
      ActionButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
    >
  >;
};

export default SubmitButton;
