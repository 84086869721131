import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { slate1 } from '@engage/colors';
import type { MemberCard } from '@engage/members';
import { useReduxState } from '@engage/redux';
import {
  capsHeader7Large,
  capsHeader7Small,
  header5Large,
  header5Small,
} from '@engage/typography';
import { useFacebook, Facebook, FacebookCopy } from '@members/copy';
import { InviteFriendsContent, ContentType } from '@members/guest-pass/components';
import { getIsElligibleGuestPassUser } from '@members/guest-pass/redux';
import { MemberList as MemberListView, MembersListModalHeader } from '@members/members';
import { FixedContainer } from './Containers';
import type { FacebookFriendCards } from './models/FacebookFriendCards';

const FindFbFriends: React.FC<React.PropsWithChildren<FindFbFriendsProps>> = props => {
  const countCopy = useFacebook(Facebook.FriendCount, { friendCount: props.total ?? 0 });
  const isElligibleGuestPassUser = useReduxState(getIsElligibleGuestPassUser);
  return props.friends && typeof props.total !== 'undefined' ? (
    <>
      {isElligibleGuestPassUser && props.total === 0 ? (
        <InviteFriendsContent
          contentType={ContentType.NoFacebookFriends}
          onClose={props.clearMemberSearch}
        />
      ) : (
        <>
          <FriendsCountBlock key="count">{countCopy}</FriendsCountBlock>
          {props.total > 0 ? (
            <FriendsContent key="friends" friends={props.friends} />
          ) : (
            <NoFriendsContent key="friends" />
          )}{' '}
        </>
      )}
    </>
  ) : null;
};

const NoFriendsContent: React.FC<React.PropsWithChildren<{}>> = () => (
  <FixedContainer>
    <MainText>
      <FacebookCopy id={Facebook.NoFriends} />
    </MainText>
  </FixedContainer>
);

const FriendsContent: React.FC<React.PropsWithChildren<{ friends: MemberCard[] }>> = ({
  friends,
}) => (
  <ScrollContainer>
    <MemberListView members={friends} />
  </ScrollContainer>
);

const ScrollContainer = styled.div`
  flex: 1;
  overflow: auto;
`;

const MainText = styled.div`
  ${header5Small}
  color: ${slate1};
  text-align: center;
  ${media.tablet`
    ${header5Large}
    color: ${slate1};
  `}
`;

const FriendsCountBlock = styled(MembersListModalHeader)`
  ${capsHeader7Small}
  height: 50px;
  min-height: 50px;
  ${media.tablet`
    ${capsHeader7Large}
    height: 65px;
    min-height: 65px;
  `}
`;

export type FindFbFriendsProps = Partial<FacebookFriendCards> & {
  clearMemberSearch?: () => void;
};

export default FindFbFriends;
