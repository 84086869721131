import type { ApolloError } from 'apollo-client';
import type { RideSubsegment } from '@engage/api-v2-models';
import type { Averages } from '@engage/class-detail/api';
import type { ApiClassDetails } from '@engage/classes';
import type { PendingResult } from '@engage/result';
import type { Playlist, RadioData } from '@engage/video-ui/models';

export enum MetricsType {
  Cycling = 'cycling',
  Running = 'running',
  Floor = 'floor',
}

export type IsInStacks = PendingResult<boolean, ApolloError>;

export type Segment = {
  id: string;
  startTimeOffset: number;
  length: number;
  name: string;
  metricsType: MetricsType;
  intensityInMets: number;
  iconName: string;
  iconSlug: string;
  iconUrl: string;
  subsegmentsV2?: Array<RideSubsegment>;
};

export type ClassDetail = {
  playlist: Playlist;
  radioData?: RadioData;
  segments?: Segment[];
  ride: ApiClassDetails;
  averages: Averages;
  sampledTopTags: Array<string> | null | undefined;
};
