import { transparentize } from 'polished';
import type { NavLinkProps } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { hover, makeStyledWithCustomProps } from '@peloton/styles';
import { blue2, slate1, slate2, gray1, gray2, superRedA11y } from '@engage/colors';
import { spaces } from '@engage/styles';
import { interV, label14, label16 } from '@engage/typography';
import NavLink from './nav/NavLink';

export const MAIN_LINK_PADDING = 15;

export const mainLinkStyles = css`
  align-items: center;
  display: inline-flex;
  fill: ${slate1};
  color: ${slate1};
  & + & {
    margin-left: 10px;
  }
`;

export const focusLinkStyles = css`
  &:focus {
    outline-color: ${blue2};
  }
`;

export const layoutTextStyles = css`
  font-family: ${interV};
  color: ${slate1};
`;

export const hoverLinkStyles = css`
  border-radius: ${spaces.xxxxSmall}px;
  cursor: pointer;
  ${hover`
    transition: background-color 250ms ease;
    background-color: ${gray2};
  `}
`;

const topNavStyles = css`
  ${layoutTextStyles}
  line-height: 1.3em;
  letter-spacing: 0.3px;
`;

export const navMainLink = css`
  ${label14}
  color: ${slate1};
`;

export const navSubLink = css`
  ${topNavStyles}
  font-weight: 400;
  font-size: 13px;
`;

export const mainLinkCss = css`
  ${hoverLinkStyles}
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${spaces.xxxSmall}px ${MAIN_LINK_PADDING}px ${spaces.xxSmall}px
    ${MAIN_LINK_PADDING}px;
`;

export const MainLink = styled(NavLink)`
  ${mainLinkCss}
  ${navMainLink}
  ${focusLinkStyles}
`;

export const MainText = styled.div`
  ${navMainLink}
  margin-left: 5px;
`;

export const Separator = styled.div`
  height: 24px;
  width: 1px;
  background-color: ${transparentize(0.7, slate1)};
  margin: 0 5px;
`;

export const OldSeparator = styled.div`
  height: 15px;
  width: 1px;
  background-color: ${transparentize(0.7, slate1)};
  margin: 0 5px;
`;

// MOBILE MENU
const mobileNavLinkStyles = css`
  ${layoutTextStyles}
  line-height: 1.25em;
  font-weight: 400;
`;

export const mobileNavMainLink = css`
  ${mobileNavLinkStyles}
  ${label16}
    color: ${slate2};
`;

export const mobileNavSubLink = css`
  ${mobileNavLinkStyles}
  ${label14}
  color: ${slate2};
  letter-spacing: 0;
`;

// MOBILE MENU OLD
const mobileNavLinkStylesOld = css`
  ${layoutTextStyles}
  line-height: 1.25em;
  font-weight: 400;
`;

export const mobileNavMainLinkOld = css`
  ${mobileNavLinkStylesOld}
  font-size: 20px;
  letter-spacing: -0.02em;
`;

export const mobileNavSubLinkOld = css`
  ${mobileNavLinkStylesOld}
  font-size: 16px;
  letter-spacing: 0;
`;

// USER NAV
const userNavLinkStyles = css`
  ${layoutTextStyles}
  font-weight: 500;
  text-transform: uppercase;
`;

export const userNavLink = css`
  ${userNavLinkStyles}
  font-size: 13px;
  letter-spacing: 0.7px;
`;

export const mobileUserNavLink = css`
  ${userNavLinkStyles}
  font-size: 12px;
  letter-spacing: 0.6px;
`;

export const userNavUsername = css`
  ${layoutTextStyles}
  line-height: 1.3em;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.4px;
`;

export const userNavHoverLinkStyles = css`
  ${hoverLinkStyles}
  cursor: pointer;
  padding: 10px ${MAIN_LINK_PADDING}px;
  ${hover`
    background-color: ${gray1};
  `}
`;

export const makeLinkActivatable = <
  C extends React.ComponentType<
    React.PropsWithChildren<
      NavLinkProps & {
        isExternal?: boolean;
      }
    >
  >
>(
  link: C,
) => {
  const activeClassName = 'navlink-active';

  return makeStyledWithCustomProps<{ handleClick?(): void }, false>()(link).attrs(() => ({
    activeClassName,
  }))`
    &.${activeClassName} {
      color: ${superRedA11y};
      fill: ${superRedA11y};
    }
  `;
};
