// eslint-disable-next-line no-restricted-imports
import { compose, prop, map } from 'ramda';
import { ContentLocale } from '@peloton/internationalize';
import { toTime } from '@peloton/time';
import { isDefined } from '@peloton/types';
import type {
  ApiClassDetails,
  LiveClass,
  OnDemandClass,
  ScenicClass,
  SharedClassInfo,
} from './models';
import { ClassCategory } from './models';
import type { ApiClass } from './models/ApiClass';
import type { Class } from './models/Class';
import type { ClassStatus } from './models/ClassStatus';
import { ContentFormat } from './models/ContentFormat';
import { ContentProvider } from './models/ContentProvider';
import { Metrics } from './models/Metrics';
import type { Peloton } from './models/Peloton';

const toTimeOrUndefined = (time?: number) => (time ? toTime(time) : undefined);

export const mapRideToClass = (ride: ApiClassDetails): Class => {
  if (isScenic(ride)) {
    return toScenicClass(ride);
  } else if (isLive(ride)) {
    return toLiveClass(ride);
  } else {
    return toOnDemandClass(ride);
  }
};

const toOnDemandClass = (apiClass: ApiClass): OnDemandClass => ({
  ...toAired(apiClass),
  ...toSharedClassInfo(apiClass),
  ...toInstructed(apiClass),
  ...toSharedOnDemand(apiClass),
  kind: ClassCategory.OnDemand,
});

const toLiveClass = (apiClass: ApiClass): LiveClass => ({
  ...toSharedClassInfo(apiClass),
  ...toAired(apiClass),
  ...toInstructed(apiClass),
  liveStreamUrl: (apiClass.liveStreamUrl ?? '').trim(),
  studioPelotonId: apiClass.studioPelotonId,
  homePelotonId: apiClass.homePelotonId,
  kind: ClassCategory.Live,
  liveClassCategory: apiClass.liveClassCategory,
});

const toScenicClass = (apiClass: ApiClass): ScenicClass => ({
  ...toSharedClassInfo(apiClass),
  ...toSharedOnDemand(apiClass),
  kind: ClassCategory.Scenic,
});

const isScenic = (apiClass: ApiClass) =>
  apiClass.contentProvider === ContentProvider.VirtualActive;

const isLive = (apiClass: ApiClass) =>
  !!apiClass.liveStreamUrl || apiClass.pedalingEndOffset === 0;

const toSharedClassInfo = (apiClass: ApiClass): SharedClassInfo => ({
  activityStartOffset: apiClass.pedalingStartOffset,
  activityEndOffset: apiClass.pedalingEndOffset,
  captionLocales: apiClass.captions
    ? apiClass.captions.map(locale => toContentLocaleFromString(locale)).filter(isDefined)
    : [],
  contentAvailability: apiClass.contentAvailability,
  classTypeIds: apiClass.classTypeIds,
  contentFormat: toContentFormat(apiClass.contentFormat),
  contentProvider: apiClass.contentProvider,
  description: apiClass.description,
  duration: apiClass.duration,
  fitnessDisciplineSlug: apiClass.fitnessDiscipline,
  hasClosedCaptions: apiClass.hasClosedCaptions || false,
  hasFreeMode: apiClass.hasFreeMode || false,
  id: apiClass.id,
  imageUrl: apiClass.imageUrl,
  isExplicit: apiClass.isExplicit,
  isLimitedRide: apiClass.isLimitedRide,
  freeForLimitedTime: apiClass.freeForLimitedTime,
  explicitRating: apiClass.explicitRating,
  joinTokens: apiClass.joinTokens,
  title: apiClass.title,
  ...(apiClass.equipmentIds ? { equipmentIds: apiClass.equipmentIds } : {}),
  supportedMetrics: [...(apiClass.metrics ?? []), Metrics.SubsegmentId],
  originLocale: apiClass.originLocale,
  userCaptionLocales: apiClass.userCaptionLocales,
});

const toSharedOnDemand = (apiClass: ApiClassDetails) => ({
  difficultyRatingAvg: apiClass.difficultyRatingAvg!,
  difficultyRatingCount: apiClass.difficultyRatingCount!,
  isBookmarked: apiClass.isFavorite,
  isInLibrary: apiClass.isArchived,
  overallRatingAvg: apiClass.overallRatingAvg!,
  overallRatingCount: apiClass.overallRatingCount!,
  totalFollowingWorkouts: apiClass.totalFollowingWorkouts!,
  totalUserWorkouts: apiClass.totalUserWorkouts!,
  totalWorkouts: apiClass.totalWorkouts!,
  vodStreamUrl: apiClass.vodStreamUrl!,
  contentAvailability: apiClass.contentAvailability!,
  isLimitedRide: apiClass.isLimitedRide!,
});

const toContentLocaleFromString = (locale: string) => {
  const key = Object.keys(ContentLocale).find(
    k =>
      ContentLocale[k as keyof typeof ContentLocale].toLowerCase() ===
      locale.toLowerCase(),
  );

  return key ? ContentLocale[key as keyof typeof ContentLocale] : undefined;
};

const toContentFormat = (apiContentFormat?: string) =>
  !apiContentFormat || apiContentFormat === 'video'
    ? ContentFormat.Video
    : ContentFormat.Audio;

const toInstructed = (apiClass: ApiClass) => ({
  instructorId: apiClass.instructorId,
});

const toAired = (apiClass: ApiClass) => ({
  language: apiClass.language,
  scheduledStartTime: toTime(apiClass.scheduledStartTime || apiClass.originalAirTime!),
  difficultyLevel: apiClass.difficultyLevel,
  originLocale: apiClass.originLocale,
});

export const toPeloton = (peloton: ApiPeloton): Peloton => ({
  id: peloton.id,
  startTime: toTimeOrUndefined(peloton.startTime),
  endTime: toTimeOrUndefined(peloton.endTime),
  classStartTime: toTimeOrUndefined(peloton.pedalingStartTime),
  classEndTime: toTimeOrUndefined(peloton.pedalingEndTime),
  rideId: peloton.rideId,
  scheduledStartTime: toTime(peloton.scheduledStartTime),
});

export const toClass = compose<{ ride: ApiClass }, ApiClass, Class>(
  mapRideToClass,
  prop('ride'),
);

export const toClasses = compose<{ rides: ApiClass[] }, ApiClass[], Class[]>(
  map(mapRideToClass),
  prop('rides'),
);

export const toLiveClasses = compose<{ rides: ApiClass[] }, ApiClass[], Class[]>(
  map<ApiClass, LiveClass>(ride => toLiveClass(ride)),
  prop('rides'),
);

export type ApiPeloton = {
  authedUserReservationId?: string;
  id: string;
  scheduledStartTime: number;
  rideId: string;
  status: ClassStatus;
  isCountedIn: boolean;
  isEncore: boolean;
  startTime?: number;
  endTime?: number;
  pedalingStartTime?: number;
  pedalingEndTime?: number;
  joinToken?: string;
  liveClassCategory?: string;
};
